.Settings_sidebar_container {
  margin: 0;
  padding: 0;
  width: 194 * $unit;
  background-color: $primary;
  position: absolute;
  height: 86%;
  overflow: auto;
}
.Settings_sidebar_nav_container {
  display: block;
  text-decoration: none;
  padding-top: 40 * $unit;
}
.Settings_sidebar_nav_item_inactive {
  width: 194 * $unit;
  height: 80 * $unit;
  color: white;
  background-color: $primary;
  text-align: center;
  padding-top: 30 * $unit;
}
.Settings_sidebar_nav_item_active {
  width: 194 * $unit;
  height: 80 * $unit;
  color: $secondary;
  background-color: white;
  text-align: center;
  padding-top: 30 * $unit;
}
.Settings_sidebar_nav_item_icon {
  font-size: 40 * $unit !important;
}
.Settings_sidebar_nav_item_inactive:hover {
  background-color: white;
  color: $secondary;
}
.Settings {
  display: flex;
}
.Settings_content_container {
  flex-grow: 100;
  background-color: white;
  margin-left: 230 * $unit;
  margin-right: 40 * $unit;
  margin-top: 40 * $unit;
}
