.Notifications_nav_container {
  height: 106 * $unit;
  background-color: white;
}
.Notifications_nav_title {
  font-family: $font;
  font-size: 30 * $unit;
  font-weight: 900;
  padding-top: 40 * $unit;
  padding-left: 40 * $unit;
  display: inline-block;
}
.Notifications_nav_clear {
  float: right;
  font-family: $font;
  font-size: 25 * $unit;
  color: #06b6ef;
  font-weight: 900;
  margin-top: 45 * $unit;
  margin-right: 40 * $unit;
  cursor: pointer;
}
.Notifications_list_container {
  padding-left: 40 * $unit;
  padding-right: 40 * $unit;
  margin-top: 30 * $unit;
}
.Notifications_list_item {
  height: 130 * $unit;
  background-color: white;
  border-bottom: solid 1 * $unit rgba(219,215,215,0.5);
  border-right: solid 1 * $unit rgba(219,215,215,0.5);
  border-left: solid 1 * $unit rgba(219,215,215,0.5);
}
.Notifications_list_item:first-child {
  height: 130 * $unit;
  background-color: white;
  border: solid 1 * $unit rgba(219,215,215,0.5);
}
.Notifications_list_item_icon {
  height: 80 * $unit;
  width: 80 * $unit;
  box-shadow: 0 3 * $unit 6 * $unit 0 rgba(0, 0, 0, 0.16);
  border-radius: 50%;
  margin-top: 24 * $unit;
  margin-left: 40 * $unit;
}
.Notifications_list_item_message {
  position: relative;
  left: 170 * $unit;
  top: -65 * $unit;
  font-size: 25 * $unit;
  font-family: $font;
  font-weight: 900;
}
.Notifications_list_item_time {
  position: relative;
  left: 170 * $unit;
  top: -52 * $unit;
  font-family: $font;
  font-size: 20 * $unit;
  font-weight: 900;
  color: #707070;
}
