.Custom_Team_banner {
  position: relative;
  border: solid 1 * $unit rgba(219, 215, 215, 0.5);
  width: 326 * $unit;
  height: 600 * $unit;
  background-color: white;
}
.Custom_Team_banner_top_background {
  position: absolute;
  width: 324 * $unit;
  height: 155 * $unit;
}

.Custom_Team_banner_top_name {
  position: absolute;
  top: 40 * $unit;
  width: 324 * $unit;
  font-size: 30 * $unit;
  text-align: center;
  color: white;
}

.Custom_Team_banner_center_header {
  position: absolute;
  top: 80 * $unit;
  left: 24 * $unit;
  background-color: white;
  width: 275 * $unit;
  height: 150 * $unit;
  border-radius: 24 * $unit;
}

.Custom_Team_banner_center_header_container {
  height: 75 * $unit;
  padding: 15 * $unit;
  text-align: center;
}

.Custom_Team_banner_center_header_price {
  font-size: 37 * $unit;
  text-align: center;
  margin-top: 15 * $unit;
}
.Custom_Team_banner_center_header_text {
  font-size: 22 * $unit;
  text-align: center;
  margin-top: 12 * $unit;
}
.Custom_Team_banner_center_header_rating_container {
  position: absolute;
  padding: 5 * $unit;
  margin-top: 10 * $unit;
  text-align: center;
  border-radius: 34 * $unit;
  border: solid 1 * $unit rgba(219, 215, 215, 0.5);
  width: 230 * $unit;
  height: 35 * $unit;
}
.Custom_Team_banner_center_header_rating_rating {
  position: absolute;
  left: 5 * $unit;
  top: 7 * $unit;
}

.Custom_Team_banner_center_header_rating_reviews {
  position: absolute;
  left: 118 * $unit;
  top: 6.5 * $unit;
  font-size: 10 * $unit;
  text-decoration: underline;
}
.Custom_Team_banner_description_container {
  background-color: white;
  height: 220 * $unit;
  width: 298 * $unit;
  position: absolute;
  top: 278 * $unit;
  left: 14 * $unit;
  padding: 5 * $unit 18 * $unit 30 * $unit 18 * $unit;
  overflow: hidden;
  overflow-y: hidden;
}
.Custom_Team_banner_description_text {
  font-size: 17 * $unit;
  color: black;
  line-height: 1.43;
  overflow-wrap: break-word;
  overflow: hidden;
  overflow-y: hidden;
}
.Custom_Team_banner_footer_container {
  position: absolute;
  top: 450 * $unit;
  left: 2 * $unit;
  width: 322 * $unit;
  height: 146 * $unit;
  background-color: #faf9fa;
  padding-top: 6 * $unit;
}
.Custom_Team_banner_footer_title {
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 17 * $unit;
  color: black;
  margin-top: 10 * $unit;
  font-family: $font;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #0063a0;
}
.Custom_Team_banner_footer_stats_container {
  height: 36 * $unit;
}
.Custom_Team_banner_footer_stats_one {
  display: inline-block;
  width: 73 * $unit;
  height: 36 * $unit;
  background-image: linear-gradient(to left, rgba(0, 92, 148, 0.87), rgba(0, 46, 74, 0.84));
  margin-bottom: 10 * $unit;
  color: white;
}

.Custom_Team_banner_footer_stats_one_title {
  font-size: 6px !important;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 0;
}

.Custom_Team_banner_footer_stats_one_stat {
  font-size: 16 * $unit;
  text-align: center;
}

.Custom_Team_banner_footer_stats_two {
  margin-left: 2 * $unit;
  display: inline-block;
  width: 73 * $unit;
  height: 36 * $unit;
  background-image: linear-gradient(to left, #297cce, #045fba);
  margin-bottom: 10 * $unit;
  color: white;
}

.Custom_Team_banner_footer_stats_two_title {
  font-size: 6px !important;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 0;
}

.Custom_Team_banner_footer_stats_two_stat {
  font-size: 16 * $unit;
  text-align: center;
}

.Custom_Team_banner_footer_stats_three {
  margin-left: 2 * $unit;
  display: inline-block;
  width: 73 * $unit;
  height: 36 * $unit;
  background-image: linear-gradient(to left, #359ff9, #1686e5);
  margin-bottom: 10 * $unit;
  color: white;
}

.Custom_Team_banner_footer_stats_three_title {
  font-size: 6px !important;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 0;
}

.Custom_Team_banner_footer_stats_three_stat {
  font-size: 16 * $unit;
  text-align: center;
}

.Custom_Team_banner_footer_stats_four {
  margin-left: 2 * $unit;
  display: inline-block;
  width: 73 * $unit;
  height: 36 * $unit;
  background-image: linear-gradient(to left, #12b0f2, #00a3e6);
  margin-bottom: 10 * $unit;
  color: white;
}

.Custom_Team_banner_footer_stats_four_title {
  font-size: 6px !important;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 0;
}

.Custom_Team_banner_footer_stats_four_stat {
  font-size: 16 * $unit;
  text-align: center;
}

.Custom_Team_banner_footer_download_buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 10 * $unit;
}

.Custom_Team_banner_footer_download_buttons_images {
  margin-top: 5 * $unit;
  width: 135 * $unit;
  height: 45 * $unit;
}

.Custom_Team_banner_footer_text {
  position: absolute;
  margin-top: 12 * $unit;
  margin-left: 60 * $unit;
  font-family: $font;
  font-size: 15 * $unit;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #7b7b7b;
}
.Custom_Team_banner_footer_text_logo {
  position: absolute;
  left: 85 * $unit;
  top: 2 * $unit;
  width: 100 * $unit;
}
