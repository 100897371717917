.Settings_payments_account_container {
  height: 350 * $unit;
  padding-top: 50 * $unit;
  padding-left: 30 * $unit;
}
.Settings_payments_account_create {
  height: 55 * $unit;
  width: 385 * $unit;
  font-size: 20 * $unit;
  font-family: Avenir;
  padding-top: 18 * $unit;
  padding-left: 20 * $unit !important;
  color: $primary;
  text-align: left;
  cursor: pointer;
}
.Settings_payments_account_item_logo {
  height: 42 * $unit;
  width: 42 * $unit;
}
.Settings_payments_account_item_container {
  box-shadow: 0 3 * $unit 6 * $unit 0 rgba(0, 0, 0, 0.16);
  width: 385 * $unit;
  height: 80 * $unit;
}

.Settings_payments_account_item_logo {
  position: relative;
  left: 20 * $unit;
  top: 18 * $unit;
}
.Settings_payments_account_item_name {
  position: relative;
  left: 90 * $unit;
  top: -25 * $unit;
  font-size: 20 * $unit;
}
.Settings_payments_account_item_details {
  position: relative;
  left: 90 * $unit;
  top: -20 * $unit;
  font-family: Avenir;
  font-size: 18 * $unit;
  margin-top: 2 * $unit;
}
