
.NotFound_background_container {
  height: 100vh;
  -webkit-background-size: cover;
  background-color:#072939;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.NotFound_message {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 70% !important;
  padding-top:5% !important;
}
.NotFound_main_back_button {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  position: relative;
  top:-150*$unit;
  left:15*$unit;
  width: 250*$unit;
  height: 65*$unit;
  border-radius: 68*$unit;
  background-color: $secondary;
  color:white;
  font-size: 30*$unit;
  font-weight: 700;
  font-family:Avenir;
  border:none;
}

@media only screen and (max-width: 1080px) {
  .NotFound_main_back_button {
    width: 125*$unit;
    height: 33*$unit;
    font-size:12*$unit;
    top:0*$unit;
  }
}