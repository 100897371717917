.Rep_banner_container {
  width: 364 * $unit;
  height: 500 * $unit;
  -webkit-backdrop-filter: blur(30 * $unit);
  backdrop-filter: blur(30 * $unit);
  box-shadow: 0 3 * $unit 6 * $unit 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
}
.Rep_banner_top_container {
  height: 135 * $unit;
  position: absolute;
}
.Rep_banner_top_image {
  width: 99 * $unit;
  height: 99 * $unit;
  position: relative;
  left: 30 * $unit;
  top: 40 * $unit;
  border-radius:50%;
}
.Rep_banner_top_description {
  position: relative;
  left: 150 * $unit;
  top: -60 * $unit;
}
.Rep_banner_top_description_name {
  font-size: 20 * $unit;
  font-family: $font;
}
.Rep_banner_top_description_since {
  margin-top: 10 * $unit;
  font-size: 15 * $unit;
  font-family: $font;
  color: #7b7b7b;
}
.Rep_banner_top_description_rating_container {
  margin-top: 5 * $unit;
  position: absolute;
  padding: 5 * $unit;
  text-align: center;
  width: 230 * $unit;
  height: 30 * $unit;
}
.Rep_banner_description_container {
  position: relative;
  left: 20 * $unit;
  top: 165 * $unit;
  height: 80 * $unit;
  width: 333 * $unit;
  font-size: 15 * $unit;
  line-height: 1.4;
  overflow-wrap: break-word;
  overflow: hidden;
  overflow-y:hidden;
  font-weight: 900;
  color: #7b7b7b;
}
.Rep_banner_video_container {
  position: relative;
  left: 30 * $unit;
  top: 188 * $unit;
  height: 150 * $unit;
  width: 305 * $unit;
}
.Rep_banner_buttons_group {
  position: relative;
  top: 214 * $unit;
}
.Rep_banner_buttons_reject {
  display: inline-block;
  margin-left: 40 * $unit;
  width: 127 * $unit;
  height: 35 * $unit;
  border-radius: 7 * $unit;
  background-image: linear-gradient(227deg, #f60020, #7b0010);
  font-family: $font;
  font-size: 14 * $unit;
  padding-top: 3 * $unit;
  padding-bottom: 2 * $unit;
  color: white;
}
.Rep_banner_buttons_select {
  margin-left: 33 * $unit;
  width: 127 * $unit;
  height: 35 * $unit;
  border-radius: 7 * $unit;
  background-image: linear-gradient(115deg, #30b100, #185900);
  font-family: $font;
  font-size: 14 * $unit;
  padding-top: 3 * $unit;
  padding-bottom: 2 * $unit;
  color: white;
}
.Rep_banner_modal_button_left {
  background-color: $secondary !important;
  color:white !important;
  padding-bottom: 17*$unit !important;
  margin-right:15*$unit !important;
}
.Rep_banner_modal_button_right {
  background-color: black !important;
  color:white !important;
  padding-bottom: 17*$unit !important;
}
.Rep_banner_RepReview_container {
  position: absolute;
  left: 114 * $unit;
  top:5*$unit;
}
.Rep_banner_top_description_rating_rating {
  position: absolute;
  left: 0 * $unit;
}
.Rep_banner_top_description_more_container {
  font-size:16*$unit;
  font-weight:900;
  color:$primary;
  margin-top:34*$unit;
  text-decoration:underline;
  cursor: pointer;
}
.ui.large.left.corner.label.Rep_banner_ribbon {
  border-color:#f60020 !important;
}
.Rep_banner_ribbon_text {
  transform:rotate(-45deg);
  margin-right:17*$unit;
  margin-top:13.5*$unit;
  font-size:15*$unit;
  font-weight:900;
  color:white !important;
}
.RepBanner_container_header_rating_review {
  position:absolute;
  left:220*$unit;
  top:0;
  font-size: 18*$unit;
  font-weight: 900;
  color: $secondary;
  width:100*$unit;
}
.Rep_Banner_moreinfo_content {
  margin-top:20*$unit;
  height:600*$unit;
}
.Rep_Banner_moreinfo_content_bio {
  height:50*$unit;
  background-color:#f4f7f9; 
  position:absolute;
  width:420*$unit;
  height:480*$unit;
  left:85*$unit;
  padding:30*$unit;
  font-size:18*$unit;
  overflow-wrap: break-word;
  overflow: hidden;
  overflow-y:hidden;
  color: #7b7b7b
}
.Rep_Banner_moreinfo_content_resume {
  height:50*$unit;
  background-color:#f4f7f9;
  width:420*$unit;
  position:absolute;
  left:595*$unit;
  height:480*$unit;
  padding:30*$unit;
}
.Rep_Banner_moreinfo_container {
  height:800*$unit;
}
.Rep_Banner_moreinfo_content_resume_component {
  width: 340*$unit !important;
  height: 420*$unit !important;
}
.Resume_center {
  text-align:center;
}
.react-pdf__Page__canvas {
  margin-left: auto !important;
  margin-right: auto !important;
}
.Resume_download_link {
  font-size: 12*$unit;
  font-weight:900;
  color:$primary;
  font:$font;
  position:absolute;
  top:440*$unit;
  left:170*$unit;
  text-decoration:underline;
}