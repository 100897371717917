.TeamCreate_overall_container{
  min-height:1200*$unit;
  margin: 0 !important;
}

.Sidebar_Container {
  margin-top: 100 * $unit;
  width: 2000 * $unit !important;
}

.TeamCreate_Sidebar_Container {
  margin-right: 120 * $unit;
}

.TeamCreate_Sidebar {
    background-color: #ffffff !important;
    font-size: 30 * $unit; 
}

.TeamCreate_Sidebar_Step {
  border-radius: 7 * $unit !important;
  -webkit-backdrop-filter: blur(30 * $unit)!important;
  backdrop-filter: blur(30 * $unit)!important;
  box-shadow: 3 * $unit 3 * $unit 9 * $unit 0 rgba(0, 0, 0, 0.16) !important;
  background-color: #ffffff !important;
  z-index: -50 !important;
  display:flex;
  justify-content: right;
  height: 144 * $unit;
  width: 180 * $unit;
  margin-bottom: 30 * $unit;
}

.TeamCreate_Sidebar_Step.active {
  border-radius: 7 * $unit !important;
  -webkit-backdrop-filter: blur(30 * $unit)!important;
  backdrop-filter: blur(30 * $unit)!important;
  box-shadow: 3 * $unit 3 * $unit 9 * $unit 0 rgba(0, 0, 0, 0.16) !important;
  background-color: #ffffff !important;
  z-index: -50 !important;
  display:flex;
  justify-content: right;
  height: 144 * $unit;
  width: 355 * $unit;
  margin-bottom: 30 * $unit;
}
.TeamCreate_Sidebar_Step.active img {
  margin-left: 25*$unit;
}
.TeamCreate_Sidebar_Step img {
  margin-left:50*$unit;
}

.TeamCreate_Sidebar_icon_1 {
  width:100*$unit;
  margin-top: 26 * $unit;
  margin-bottom: 26 * $unit;
  margin-left: 20 * $unit;
  margin-right: 21.8 * $unit;
  cursor:pointer;
}
.TeamCreate_Sidebar_icon_2 {
  width:75*$unit;
  margin-top: 26 * $unit;
  margin-bottom: 26 * $unit;
  margin-left: 32 * $unit;
  margin-right: 21.8 * $unit;
  cursor:pointer;
}

.TeamCreate_Sidebar_icon_3 {
  width:90*$unit;
  margin-top: 26 * $unit;
  margin-bottom: 26 * $unit;
  margin-left: 29 * $unit;
  margin-right: 21.8 * $unit;
  cursor:pointer;
}

.TeamCreate_Sidebar_icon_4 {
  width:90*$unit;
  margin-top: 26 * $unit;
  margin-bottom: 26 * $unit;
  margin-left: 29 * $unit;
  margin-right: 21.8 * $unit;
  cursor:pointer;
}
.TeamCreate_Sidebar_label {
  position:absolute;
  left:170*$unit;
  visibility:hidden;
}
.TeamCreate_Sidebar_label.active {
  position:absolute;
  left:160*$unit;
  visibility:visible;
}

.TeamCreate_Sidebar_Title {
  font-family: $font;
  font-size: 22 * $unit;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #7b7b7b;
  margin-top: 38 * $unit;
}

.TeamCreate_Sidebar_Description {
  font-family: $font;
  font-size: 28 * $unit;
  font-weight: 900 !important;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: black;
}

.Sidebar_Compensation_icon {
}

.Sidebar_Customization_icon {
}

.Sidebar_Publication_icon {
}

.TeamCreate_Container {
  margin-top: 15 * $unit;
  width: 1100 * $unit;
  min-height: 1100 * $unit;
}

.TeamCreate_Container_Header {
  background-color: #0063a0;
  font-family: $font;
  font-size: 25 * $unit;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

.TeamCreate_Header {
  margin-top: 38* $unit;
  margin-bottom: 38 * $unit;
  margin-left:auto;
  margin-right:auto;
}

#vertical_dotted_line0 {
  border-left: 5*$unit dotted #dbd7d7;
  height: 50*$unit;
  position: absolute;
  margin-left: 84 * $unit;
  padding-left: -200 * $unit;
}
#vertical_dotted_line1 {
  border-left: 5*$unit dotted #dbd7d7;
  height: 250*$unit;
  position: absolute;
  margin-left: 84 * $unit;
  padding-left: -200 * $unit;
}
#vertical_dotted_line2 {
  border-left: 5*$unit dotted #dbd7d7;
  height: 350*$unit;
  position: absolute;
  margin-left: 84 * $unit;
  padding-left: -200 * $unit;
}
#vertical_dotted_line3 {
  border-left: 5*$unit dotted #dbd7d7;
  height: 550*$unit;
  position: absolute;
  margin-left: 84 * $unit;
  padding-left: -200 * $unit;
}
#vertical_dotted_line4 {
  border-left: 5*$unit dotted #dbd7d7;
  height: 550*$unit;
  position: absolute;
  margin-left: 84 * $unit;
  padding-left: -200 * $unit;
}

.Team_Create_Nav_Bar {
  background-color: #0065a2;
  width: 180 * $unit;
  height: 51 * $unit;
  border: 1px #dbd7d7 solid;
  border-radius: 7px !important;
  font-family: $font;
  font-size: 20 * $unit;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color:white;
  cursor: pointer !important;
}

.Team_Create_Nav_Bar_Container {
  border-top: 1px solid #dbd7d7;
  padding-top: 20 * $unit;
  padding-left: 40 * $unit !important;
  width: 100%;
  height: 100 * $unit;
  background-color: white;
  
}

.Inactive {
  background-color: black !important;
}