.Forgot_form_submit_container button {
  width: 351 * $unit;
  height: 70 * $unit;
  border-radius: 14 * $unit;
  background-color: $primary;
  color: white;
  font-family: $font;
  font-size: 25 * $unit;
  border: 0;
  cursor: pointer;
}
