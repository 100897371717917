.Responsive_coming_soon {
  text-align:center;
  margin-top: 150 * $unit;
  padding-left:65*$unit;
  padding-right:65*$unit;
}
.Responsive_coming_soon_image {
  width: 175 * $unit;
}
.Responsive_coming_soon_top {
  font: Avenir;
  font-size: 40 * $unit;
  font-weight: 900;
  color: #969696;
  margin: 0;
  margin-top: 20 * $unit;
  margin-bottom:15 * $unit;
}
.Responsive_coming_soon_bottom {
  font: Avenir;
  font-size: 25 * $unit;
  font-weight: 900;
  color: #969696;
}
.Responsive_coming_soon_create {
  width: 219 * $unit;
  height: 51 * $unit;
  border-radius: 7 * $unit;
  background-color: $primary;
  color: white;
  font-size: 21 * $unit;
}
.Feedback_button {
  padding-bottom:18*$unit !important;
  padding-top:10*$unit !important;
  font-size:18*$unit !important;
}