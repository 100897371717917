.CompanyReviews_modal_trigger {
  color: $secondary;
  cursor:pointer;
  font-size:15*$unit;
  text-decoration: underline;
}
.CompanyReviews_container {
  height:650*$unit;
}
.CompanyReviews_container_header {
  height:180*$unit;
}
.CompanyReviews_container_header_picture{
  width: 400 * $unit;
  position: relative;
  left: 30 * $unit;
  top: 20 * $unit;
}
.CompanyReviews_container_header_description {
  position: relative;
  left: 200 * $unit;
  top: -80 * $unit;
}
.CompanyReviews_container_header_description_name {
  font-size: 27 * $unit;
  font-family: $font;
  font-weight:900;
}
.CompanyReviews_container_header_description_since {
  margin-top: 5 * $unit;
  font-size: 15 * $unit;
  font-family: $font;
  font-weight:900;
}
.CompanyReviews_container_header_rating {
  position: relative;
  left: 500 * $unit;
  top: 25 * $unit;
}
.CompanyReviews_container_header_rating_number {
  position:absolute;
  left:230*$unit;
  top:41*$unit;
  font-size:40*$unit;
  font:$font;
}
.CompanyReviews_container_header_rating_rating {
  position:absolute;
  left:100*$unit;
  top:100*$unit;
}
.CompanyReviews_container_header_rating_review {
  position:absolute;
  left:220*$unit;
  top:96*$unit;
  font-size: 18*$unit;
  font-weight: 900;
  color: $secondary;
}
.CompanyReviews_container_list_container {
  height:430*$unit;
  overflow: scroll;
  overflow-x: hidden;
}
.CompanyReviews_container_list_instance {
  height: 125*$unit;
  margin-top:14*$unit;
  margin-left:40*$unit;
  margin-right:40*$unit;
  box-shadow: 0 3*$unit 6*$unit 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;  
}

.CompanyReviews_container_list_instance_top_rep_image {
  position:relative;
  width:50*$unit;
  height:50*$unit;
  top:14*$unit;
  left:20*$unit;
  border-radius: 50%;
}
.CompanyReviews_container_list_instance_top_rep_name {
  position:relative;
  top:-38*$unit;
  left:90*$unit;
  font-size:15*$unit;
  font-weight:900;
}
.CompanyReviews_container_list_instance_top_rep_since {
  position:relative;
  top:-36*$unit;
  left:90*$unit;
  font-size:10*$unit;
  color:#707070;
  font-weight:900;
}
.CompanyReviews_container_list_instance_top_rating {
  position:relative;
  top:-70*$unit;
  left:820*$unit;
}

.CompanyReviews_container_list_instance_bottom {
  position:relative;
  top:-36*$unit;
  left:28*$unit;
  width:600*$unit;
  font-size:12*$unit;
  font:$font;
  font-weight:900;
  color: #707070;
}
.CompanyReviews_container_list_empty {
  margin-top:30*$unit;
  font-size:20*$unit;
  color: #707070;
  font:$font;
  text-align:center;
}

.Reviews_Placeholder_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top:50*$unit;
}
.Reviews_Placeholder_text_container {
  margin-top:45*$unit;
  font-size:26*$unit;
  font-weight:900;
  color:#707070;
}
.Reviews_Icon_Placeholder_image_container {
  width:100*$unit;
  margin-top:18*$unit;
}