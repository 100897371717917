.RepReviews_modal_trigger {
  color: $secondary;
  cursor:pointer;
  font-size:15*$unit;
  text-decoration: underline;
}
.RepReviews_container {
  height:650*$unit;
}
.RepReviews_container_header {
  height:180*$unit;
}
.RepReviews_container_header_picture{
  width: 130 * $unit;
  height: 130 * $unit;
  position: relative;
  left: 30 * $unit;
  top: 20 * $unit;
  border-radius:50%;
}
.RepReviews_container_header_description {
  position: relative;
  left: 200 * $unit;
  top: -80 * $unit;
}
.RepReviews_container_header_description_name {
  font-size: 27 * $unit;
  font-family: $font;
  font-weight:900;
}
.RepReviews_container_header_description_name_resume {
  font-size: 14 * $unit;
  font-family: $font;
  font-weight:900;
  margin-left:15*$unit;
  color:$secondary;
}
.RepReviews_container_header_description_since {
  margin-top: 5 * $unit;
  font-size: 15 * $unit;
  font-family: $font;
  font-weight:900;
}
.RepReviews_container_header_rating {
  position: relative;
  left: 500 * $unit;
  top: 25 * $unit;
}
.RepReviews_container_header_rating_number {
  position:absolute;
  left:230*$unit;
  top:-50*$unit;
  font-size:40*$unit;
  font:$font;
}
.RepReviews_container_header_rating_rating {
  position:absolute;
  left:100*$unit;
  top:5*$unit;
}
.RepReviews_container_header_rating_review {
  position:absolute;
  left:220*$unit;
  top:0;
  font-size: 18*$unit;
  font-weight: 900;
  color: $secondary;
}
.RepReviews_container_list_container {
  height:430*$unit;
  overflow: scroll;
  overflow-x: hidden;
}
.RepReviews_container_list_instance {
  height: 125*$unit;
  margin-top:14*$unit;
  margin-left:40*$unit;
  margin-right:40*$unit;
  box-shadow: 0 3*$unit 6*$unit 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;  
}

.RepReviews_container_list_instance_top_company {
  position:relative;
  width:180*$unit;
  top:14*$unit;
  left:30*$unit;
}
.RepReviews_container_list_instance_top_rating {
  position:relative;
  top:0*$unit;
  left:630*$unit;
}

.RepReviews_container_list_instance_bottom {
  position:relative;
  top:30*$unit;
  left:35*$unit;
  width:600*$unit;
  font-size:12*$unit;
  font:$font;
  font-weight:900;
  color: #707070;
}
.RepReviews_container_list_empty {
  margin-top:30*$unit;
  font-size:20*$unit;
  color: #707070;
  font:$font;
  text-align:center;
}