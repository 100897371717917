.Team_Create_Customization_edit_logo_button{
  display: block;
  background-color: $secondary !important;
  color: white !important;
  font-family: $font;
  font-size: 18 * $unit;
  color: #707070;
  padding-bottom:16*$unit !important;
  cursor: pointer;
  text-align: center;
  margin-top: 15 * $unit !important;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.Team_Create_Customization_edit_logo_button i::before{
  top:48% !important;
}
.Team_Create_Customization_edit_logo_input{
  visibility:hidden;
}

.Team_Create_Customization_edit_logo_container{
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  align-items: center !important;
  margin-top:30*$unit;
}
.Team_Create_Customization_edit_logo_image{
  width: 250*$unit;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

