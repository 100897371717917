.SignIn_container {
  margin-top: 65 * $unit;
}
.SignIn_header {
  font-size: 35 * $unit;
  font: Calibri;
  font-weight: lighter;
  margin-bottom: 50 * $unit;
  margin-left: 5 * $unit;
}
.SignIn_form_container {
  margin-left: 140 * $unit;
  min-width: 600 * $unit;
}
.SignIn_form_input_email {
  height: 85 * $unit;
  width: 100%;
  -webkit-appearance: 0;
  margin-bottom: 40 * $unit;
  border: 1 * $unit solid rgba(0, 0, 0, 0.2);
  text-indent: 20 * $unit;
  font-size: 30 * $unit;
  font: Avenir;
}
.SignIn_form_input_password {
  height: 85 * $unit;
  width: 100%;
  -webkit-appearance: 0;
  border: 1 * $unit solid rgba(0, 0, 0, 0.2);
  text-indent: 20 * $unit;
  font-size: 30 * $unit;
  font: Avenir;
}
.SignIn_form_option_container {
  margin-top: 20 * $unit;
}

.SignIn_form_option_remember {
  position: relative;
}
.SignIn_form_option_remember label {
  position: absolute;
  top: -20 * $unit;
  left: 40 * $unit;
  width: 200 * $unit;
  font-family: Avenir;
  font-size: 25 * $unit;
  line-height: 1.35;
  text-align: left;
  color: black;
  cursor: pointer;
}
.SignIn_form_option_remember input {
  position: absolute;
  cursor: pointer;
  top: -12 * $unit;
  left: 10 * $unit;
}
input[type='checkbox'] {
  transform: scale(1.4);
}
.SignIn_form_option_forgot a {
  color: $secondary !important;
  font-size: 22 * $unit !important;
  font-weight: 800;
}
.SignIn_form_option_forgot a {
  font-family: Avenir;
  font-size: 20 * $unit;
  line-height: 1.35;
  text-align: left;
  color: black;
}

.SignIn_form_submit_container {
  margin-top: 60 * $unit;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.SignIn_form_submit_container button {
  width: 301 * $unit;
  height: 70 * $unit;
  border-radius: 8 * $unit;
  background-color: $primary;
  color: white;
  font-family: Avenir;
  font-weight: 800;
  font-size: 25 * $unit;
  border: 0;
  cursor: pointer;
}
.SignIn_helper_container {
  margin-top: 130 * $unit;
  font-family: $font;
  font-size: 25 * $unit;
  line-height: 1.4;
  text-align: left;
  color: gray;
}

.SignIn_helper_container a {
  color: $secondary;
}

.modal-investor {
  display: none; /* Hidden by default */
  position: fixed !important; /* Stay in place */
  z-index: 500 !important; /* Sit on top */
  padding-top: 10rem !important; /* Location of the box */
  left: 0 !important;
  top: 0 !important;
  width: 100% !important; /* Full width */
  height: 100% !important; /* Full height */
  overflow: auto !important; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0) !important; /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4) !important; /* Black w/ opacity */
  text-align: center !important;
}

/* Modal Content */
.modal-investor-content {
  text-align: center !important;
  background-color: #fefefe !important;
  margin: auto !important;
  padding: 20px !important;
  border: 1px solid #888 !important;
  display: inline-block !important;
  /* width: 50%; */
  border-radius: 12px !important;
  border: solid 0.1px #707070 !important;
}

.investor-close-container {
  position: relative;
  width: 0;
  height: 0;
}
/* The Close Button */
.investor-close {
  position: absolute;
  top: -85 * $unit;
  left: 400 * $unit;
  color: #aaaaaa;
  font-size: 35 * $unit;
  font-weight: bold;
}

.investor-close:hover,
.investor-close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-investor-button-group {
  text-align: center !important;
  display: flex;
  justify-content: space-between;
}
.modal-investor-question {
  color: black;
  z-index: 50;
  font-size: 25 * $unit;
}

#repBtn {
  width: 200 * $unit;
  height: 70 * $unit;
  padding-top: 15 * $unit;
  border-radius: 14 * $unit;
  background-color: $primary;
  color: white;
  font-family: $font;
  font-size: 25 * $unit;
  border: 0;
  cursor: pointer;
  margin-right: 10 * $unit;
}
#companyBtn {
  width: 200 * $unit;
  padding-top: 15 * $unit;
  height: 70 * $unit;
  border-radius: 14 * $unit;
  background-color: $primary;
  color: white;
  font-family: $font;
  font-size: 25 * $unit;
  border: 0;
  cursor: pointer;
}
@media only screen and (max-width: 1355px) {
  .SignIn_form_container {
    margin-left: 0 * $unit;
    min-width: 0 * $unit;
  }
  .SignIn_form_submit_container button {
    width: 151 * $unit;
    height: 70 * $unit;
    border-radius: 8 * $unit;
    background-color: $primary;
    color: white;
    font-family: Avenir;
    font-weight: 800;
    font-size: 25 * $unit;
    border: 0;
    cursor: pointer;
  }
  .SignIn_helper_container {
    margin-top: 130 * $unit;
    margin-bottom: 50 * $unit;
    font-family: $font;
    font-size: 22 * $unit;
    line-height: 1.4;
    text-align: left;
    color: gray;
  }
}
