.Customization_First_Col {
  width: 50%;
  padding-left: 20 * $unit;
  padding-right: 20 * $unit;
}
.Customization_Second_Col {
  background-color: #ebebeb;
  display: flex;
  justify-content: center;
  padding: 30 * $unit;
  align-items: center;
  width: 50%;
}
.Customization_Container_Body {
  padding: 0 !important;
  width: 100%;
  display: flex !important;
  flex-direction: row !important;
  height: 586 * $unit;
  background-color: white;
  height: 100%;
}
.Customization_Second_Col_Custom_Team_Banner {
  height: 100%;
}
.Customization_Container_Upload_DnD_Logo {
  display: flex;
  flex-direction: row !important;
  width: 100%;
}
.Customization_Container_Upload_logo {
  width: 50%;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center;
  align-items: center !important;
}
.Customization_Container_Drag_Drop {
  width: 50% !important;
  display:flex;
  justify-content: flex-end;
  padding-right: 30 * $unit;
}
.Description_Container_Upload_Logo_Text {
  display: flex !important;
  flex-direction: column !important;
  align-items: space-around !important;
}
.Customization_Drag_Drop {
  border-style: dashed;
  border-width: 3 * $unit;
  border-color: #dbd7d7;
  width: 198 * $unit;
  height: 191 * $unit;
  margin-top: 20 * $unit;
  margin-bottom: 20 * $unit;
  background-color: #f3f6fc;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.Customization_Drag_Drop_Text {
  font-family: $font;
  font-size: 17 * $unit;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
  color: black
}
.Customization_Container_ColorSelector {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  align-items: center !important;
  margin-bottom: 20 * $unit;
}
.Customization_Container_ColorSelector_Text {
  font-family: $font;
  font-size: 18 * $unit;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 10 * $unit;
}
.Customization_Container_ColorSelector_Color {
  width: 20px;
  height: 20px;
  background-color: orange;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.Customization_Container_TeamName {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  margin-bottom: 30 * $unit;
  margin-top:30*$unit;
}
.Customization_Container_TeamName_Text {
  font-family: $font;
  font-size: 18 * $unit !important;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}
.Customization_Container_Compensation_Description {
  display: flex;
  flex-direction: column;
  align-content: center !important;
  margin-top:30*$unit;
}
.Customization_Container_Compensation_Description_Text {
  font-family: $font;
  font-size: 18 * $unit;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  margin-bottom: 10 * $unit;
}
.Description_Upload_Logo_Text {
  font-family: $font;
  font-size: 25 * $unit;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);
}
.Sub_Description_Upload_Logo_Text {
  font-family: $font;
  font-size: 17 * $unit !important;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: $light-peach !important;
}
.Customization_Container_Bottom_Nav {
  border-top: 1px solid rgba(219,215,215,0.5);
  display: flex;
  justify-content: space-between !important;
  background-color: white;
  padding-left: 25 * $unit !important;
  padding-right: 20 * $unit !important;
  z-index: 0
}
.Customization_Button {
  width: 100 * $unit;
  height: 51 * $unit;
  border: 1px #dbd7d7 solid;
  border-radius: 7px !important;
  background-color: #fafbfd;
  font-family: $font;
  font-size: 20 * $unit;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #707070;
}
.Customization_Button_Next {
  width: 180 * $unit;
  height: 51 * $unit;
  border: none;
  border-radius: 7px !important;
  background-color: #fafbfd;
  font-family: $font;
  font-size: 20 * $unit;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: white;
  background-color: #dbd7d7;
}
.Customization_Button_Next.ready {
  background-color: $primary !important;
}
.Customization_First_Col_Selectors {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  margin-top:30*$unit;
  margin-bottom:35*$unit
}
.ColorPicker {
  position: absolute;
  left: 620 * $unit;
  top: 300 * $unit;
  z-index: 5 !important;
}
.Customization_Color_Selector {
  width: 40*$unit;
  height: 40*$unit;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  z-index: 2 !important;
}
.hidden{
  display:none;
}
.Customization_Container_TeamName_Input input {
  background-color:#f4f7f9 !important;
}
.Customization_Container_Compensation_Description_TextArea {
  border-style: none !important; 
  border-color: white !important; 
  font-size: 20 * $unit;
  padding-top:25*$unit;
  padding-left: 30*$unit;
  font-family: $font;
  height: 200 * $unit;
  width: 570 * $unit;
  background-color:#f4f7f9;
}
.Customization_Container_Compensation_Description_TextArea_Container { 
  margin:8*$unit;
}
.Customization_Container_divider_1 { 
  border-bottom: solid 1 * $unit rgba(219,215,215,0.5);
  margin-top:5*$unit;
}
.Customization_Container_divider_2 { 
  border-bottom: solid 1 * $unit rgba(219,215,215,0.5);
  margin-top: 5*$unit;
}
.Customization_Container_divider_3 { 
  border-bottom: solid 1 * $unit rgba(219,215,215,0.5);
}
.Customization_ImageCrop_info {
  position:absolute;
  left:260*$unit;
  top:190*$unit;
  text-align:center !important;
}
.Customization_ImageCrop_info i {
  text-align:center !important;
  font-size:20*$unit;
}
.Customization_Image_Crop_info_popup {
  padding: 10 *$unit;
}
.Customization_logo_ImageCrop {
  position:absolute !important;
  left:20*$unit !important;
}