.Customization_Button_Publish{ 
  background-color: #0065a2;
  width: 180 * $unit;
  height: 51 * $unit;
  border: 1px #dbd7d7 solid;
  border-radius: 7px !important;
  font-family: $font;
  font-size: 20 * $unit;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color:white;
}
.Publication_Header {
  display:flex;
  flex-direction: row;
  justify-content: center;
}
.Modal_Container {
  background-color: white !important;
  padding: 20 * $unit;
}
.Modal_Header {
  font-family: $font;
  font-size: 35 * $unit;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  margin-bottom:15*$unit;
  margin-left:20*$unit;
}
.Modal_Content {
  font-family: $font;
  font-size: 24 * $unit;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #7b7b7b;
  width:300*$unit;
  margin-bottom:21*$unit;
  margin-left:20*$unit;
}
.Modal_Content_Pic {
  position:absolute;
  left:375*$unit;
  top:37*$unit;
  width: 130 * $unit;
}
.Modal_Container_Buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around
}
.Modal_Container_Button_Select_Reps { 
  width: 200 * $unit;
  height: 49 * $unit;
  border-radius: 7px;
  border: solid 1px rgba(219,215,215,0.5)  !important;
  background-color: #fafbfd !important;
  font-family: $font !important;
  font-size: 20*$unit !important;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #7b7b7b !important;
  padding-bottom:32*$unit !important;
}
.Modal_Container_Button_Go_Home {
  width: 200 * $unit;
  height: 49 * $unit;
  border-radius: 7px;
  background-color: #005b92 !important;
  font-family: $font !important;
  font-size: 20*$unit !important;
  padding-bottom:32*$unit !important;
}
.Publication_Second_Col {
  background-color: #ebebeb;
  display: flex;
  justify-content: center;
  padding: 30 * $unit;
  align-items: center;
  width: 50%;
}
.Publication_Container_Body {
  padding: 0 !important;
  width: 100%;
  display: flex !important;
  flex-direction: row !important;
  height: 586 * $unit;
  background-color: #ebebeb;
  height: 100%;
  justify-content:center;
}
.Publication_Second_Col_Custom_Team_Banner {
  height: 100%;
}