.Recruitment_Container_Header {
  background-color: #0063a0;
  font-family: $font;
  font-size: 25 * $unit;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

.Recruitment_Header {
  margin-top: 38 * $unit;
  margin-bottom: 38 * $unit;
  margin-left: auto;
  margin-right: auto;
}

.Recruitment_Questions_Header {
  font-family: $font;
  font-size: 21 * $unit;
  width: 900 * $unit !important;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #212121;
}

.Recruitment_Questions_Header_Extra {
  color: #707070;
}

// .Recruitment_Container_Body {
//   background-color: white;
//   height: 586 * $unit;
//   width: 940 * $unit;
//   padding:0 !important;
// }

.Recruitment_Container_ScrollSection {
  height: 370 * $unit;
  overflow: scroll;
  display: flex;
  justify-content: center;
  margin-top:30*$unit;
  overflow-x: hidden;
}

.Recruitment_Container_Bottom_Nav {
  display: flex;
  justify-content: space-between !important;
  margin-top: 35 * $unit;
  border-top: 1*$unit solid rgba(219,215,215,0.5);
  padding-top: 25 * $unit !important;
  padding-left: 25 * $unit !important;
  padding-right: 20 * $unit !important;
}

.Recruitment_Bottom_Nav_Button {
  width: 100 * $unit;
  height: 51 * $unit;
  border: 1*$unit rgba(219,215,215,0.5) solid;
  border-radius: 7*$unit !important;
  background-color: #fafbfd;
  font-family: $font;
  font-size: 20 * $unit;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #707070;
}

.Recruitment_Bottom_Nav_Button_Next {
  width: 180 * $unit;
  height: 51 * $unit;
  border:none !important;
  border-radius: 7*$unit !important;
  background-color: #fafbfd;
  font-family: $font;
  font-size: 20 * $unit;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: white;
  background-color: #dbd7d7;
}

.Recruitment_Container_Description {
  padding-left:30*$unit;
  border-bottom: solid 1 * $unit rgba(219,215,215,0.5);
}

.Recruitment_Container_Body {
  background-color: white;
  height: 660 * $unit;
  width: 100%;
  font-family: $font;
  font-size: 20*$unit;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
}

.Recruitment_Container_Industries {
  margin-bottom: 20 * $unit;
  width: 1100 * $unit;
  height: 71 * $unit;
  font-family: $font;
  font-size: 21*$unit;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none !important;
  background-color: #fbfbfb;
  outline: none !important;
}

.Recruitment_Container_Industries.Highlighted {
  background-color: #06b6ef;
  color: white !important;
  cursor: pointer;
  border: none !important;
}

.Recruitment_Container_Industries:hover {
  background-color: #06b6ef;
  color: white !important;
  cursor: pointer;
  border: none !important;
}

.Recruitment_Container_Industries:focus {
  // color: black;
}

.Recruitment_Container_Questions {
  margin-top: 20 * $unit;
  height: 70 * $unit;
  display: flex;
  flex-direction: row !important;
  justify-content: center !important;
}

.Recruitment_Container_Questions_Title {
  font-family: $font;
  font-size: 20 * $unit;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  align-self: center !important;
  margin-left: auto !important;
  text-align: left;
  color: #7b7b7b;
}

.Recruitment_Container_Questions_Icon {
  border-radius: 6*$unit;
  width: 70 * $unit !important;
  height: 70 * $unit !important;
  border: 1*$unit black solid;
  padding-top: 15 * $unit;
  border: solid 1*$unit var(--light-peach);
  background-color: #fafbfd;
  margin-left: auto !important;
  align-self: flex-end !important;
}
