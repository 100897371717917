.Detail_payments_container {
  margin-top: 30 * $unit !important;
  padding-left: 100 * $unit !important;
  display: flex;
}
.Detail_payments_main_container {
  margin-left: 200 * $unit;
  flex-grow: 100;
  margin-right: 50 * $unit;
}

.Payments_Placeholder_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top:50*$unit;
}
.Payments_Placeholder_image_container {
  width:140*$unit;
}
.Payments_Placeholder_text_container {
  margin-top:45*$unit;
  font-size:26*$unit;
  font-weight:900;
  color:#707070;
}
.Payments_Icon_Placeholder_image_container {
  width:100*$unit;
  margin-top:18*$unit;
}