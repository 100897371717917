.Detail_manage_container {
  margin-top: 30 * $unit !important;
  padding-left: 100 * $unit !important;
  display: flex;
}
.Detail_side_container {
  padding: 0 !important;
}
.Detail_side_nav {
  height: 110 * $unit !important;
  margin: 0 !important;
}
.Detail_side_nav_buttons {
  width: 326 * $unit !important;
  height: 110 * $unit !important;
  border-radius: 0 !important;
  border: 0 !important;
  color: white !important;
  margin: 0 !important;
  // background-color: $secondary !important;
}
.Detail_side_nav_buttons_icon {
  float: right;
}
.ui.button.Detail_side_nav_button_upper {
  padding-top:18*$unit !important;
}
.ui.button.Detail_side_nav_button_lower {
  padding-top:18*$unit !important;
}

.Detail_side_nav_button_upper {
  text-align: left !important;
  font-family: $font !important;
  font-size: 20 * $unit !important;
  border: solid 1 * $unit rgba(219,215,215,0.5) !important;
}
.Detail_side_nav_button_lower {
  text-align: left !important;
  font-family: $font !important;
  font-size: 20 * $unit !important;
  border-left: solid 1 * $unit rgba(219,215,215,0.5) !important;
  border-right: solid 1 * $unit rgba(219,215,215,0.5) !important;
  border-bottom: solid 1 * $unit rgba(219,215,215,0.5) !important;
}
.Detail_side_banner_container {
  margin-top: 30 * $unit;
  box-shadow: 0 3*$unit 6*$unit 0 rgba(0, 0, 0, 0.10);
}
.Detail_main_container {
  margin-left: 50 * $unit;
  flex-grow: 100;
  margin-right: 50 * $unit;
}
