.TeamView_filter_container {
  margin-top: 20 * $unit;
  height: 72 * $unit;
  background-color: white;
  border: solid 1 * $unit rgba(219, 215, 215, 0.5);
}

.TeamView_filter_search {
  display: inline-block;
  width: 390 * $unit !important;
  margin-left: 40 * $unit !important;
  height: 34 * $unit !important;
}
.Team_view_filter_search_element {
  margin-top: 19 * $unit;
  height: 34 * $unit !important;
  font-size: 15 * $unit !important;
  font-family: $font;
}

.TeamView_filter_rank {
  margin-top: 19 * $unit;
  margin-right: 47 * $unit;
  font-size: 13 * $unit !important;
  font-family: $font;
  float: right;
  width: 230 * $unit !important;
}
.TeamView_filter_rank:input {
  height: 34 * $unit;
}
.visible.menu.transition {
  margin-top: 3 * $unit !important;
  margin-left: -1 * $unit !important;
  width: 230 * $unit !important;
}

.TeamView_rep_container {
  margin-top: 22 * $unit;
  min-height: 630 * $unit;
}

.TeamView_rep_instance_container {
  background-color: white;
  height: 142 * $unit;
  margin-bottom: 20 * $unit;
  border: solid 1 * $unit rgba(219, 215, 215, 0.5);
}
.TeamView_rep_summary_container {
  height: 135 * $unit;
  position: absolute;
}

.TeamView_rep_summary_image {
  width: 104 * $unit;
  height: 104 * $unit;
  position: relative;
  left: 30 * $unit;
  top: 20 * $unit;
  border-radius: 50%;
}

.TeamView_rep_summary_description {
  position: relative;
  left: 150 * $unit;
  top: -70 * $unit;
}
.TeamView_rep_summary_description_name {
  position: relative;
  top: 5 * $unit;
  font-size: 20 * $unit;
  font-family: $font;
}
.TeamView_rep_summary_description_since {
  position: relative;
  top: 45 * $unit;
  font-size: 15 * $unit;
  font-family: $font;
  color: black;
}
.TeamView_rep_summary_description_rating_rating {
  position: absolute;
  top: 30 * $unit;
  left: -3 * $unit;
}

.TeamView_rep_summary_description_rating_reviews {
  position: absolute;
  top: 28 * $unit;
  left: 110 * $unit;
  font-size: 14 * $unit;
  color: $secondary;
}

.TeamView_rep_instance_button_container {
  float: right;
  margin-top: 56 * $unit;
}
.TeamView_rep_instance_chosen_remove {
  width: 105 * $unit;
  height: 30 * $unit;
  border-radius: 4 * $unit;
  border: solid 1 * $unit #d82d00;
  color: #d82d00;
  background-color: #ffffff;
  font-family: $font;
  font-size: 15 * $unit;
  font-weight: 900;
  padding-top: 3 * $unit;
  margin-right: 30 * $unit;
}
.TeamView_rep_instance_chosen_remove:hover {
  background-color: red;
  color: white;
  cursor: pointer;
}
.TeamView_rep_instance_chosen_detail {
  margin-left: 30 * $unit;
  margin-right: 30 * $unit;
  width: 122 * $unit;
  height: 30 * $unit;
  border-radius: 4 * $unit;
  color: white;
  background-color: $primary;
  font-family: $font;
  font-size: 15 * $unit;
  font-weight: 900;
  padding-top: 3 * $unit;
}
.TeamView_paginator {
  float: right;
  font-size: 20 * $unit;
  background-color: white;
}
.TeamView_paginator_previous {
  width: 110 * $unit;
  height: 42 * $unit;
  color: #dbd7d7;
  border: solid 0.001em #dbd7d7 !important;
}
.TeamView_paginator_number {
  width: 50 * $unit;
  height: 42 * $unit;
  color: white;
  background-color: $secondary;
  border-top: solid 0.001em #dbd7d7 !important;
  border-bottom: solid 0.001em #dbd7d7 !important;
}
.TeamView_paginator_next {
  width: 70 * $unit;
  height: 42 * $unit;
  background-color: #fafbfd;
  border: solid 0.001em #dbd7d7 !important;
}

.TeamView_Placeholder_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 180 * $unit;
}
.TeamView_Placeholder_image_container {
  width: 140 * $unit;
}
.TeamView_Placeholder_text_container {
  margin-top: 75 * $unit;
  font-size: 26 * $unit;
  font-weight: 900;
  color: #707070;
}
