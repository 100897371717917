.Desktop_container {
  position: relative;
  min-height: 100vh;
}
.Desktop_container_content {
  padding-bottom: 15rem;
}

.Desktop_menu {
  height: 120 * $unit !important;
  padding-top: 27 * $unit !important;
  padding-bottom: 27 * $unit !important;
  margin-bottom: 0 !important;
  font-family: 'Helvetica' !important;
}

.ui.menu .item {
  padding: 0 !important;
  background-color: white !important;
}

.Desktop_menu_left {
  margin-left: 30 * $unit !important;
}

.Desktop_menu_logo {
  height: 60 * $unit !important;
}

.Desktop_notifications_icon {
  margin: 0 !important;
  font-size: 42 * $unit !important;
  cursor: pointer;
}

.Desktop_menu_divider {
  border: solid 1 * $unit rgba(219,215,215,1);
  height: 66 * $unit;
  margin-left: 25 * $unit;
  margin-right: 25 * $unit;
}
.ui.borderless.Desktop_menu.menu {
  border-bottom: solid 1 * $unit rgba(219,215,215,0.5) !important;
}

.Desktop_menu_dropdown_image {
  height: 50 * $unit !important;
  width: 50 * $unit !important;
}

.Desktop_menu_dropdown_account {
  font-size: 25 * $unit !important;
  font-weight: bold;
}

i.icon.chevron.down:before {
  color: $secondary;
  font-size: 25 * $unit !important;
  // margin-left: 25 * $unit;
}

i.icon.chevron.down {
  margin-left: 25 * $unit !important;
  margin-right: 55 * $unit !important;
}

.ui.dropdown .menu {
  width: 250 * $unit !important;
  margin-left: 5 * $unit !important;
  margin-top: 15 * $unit !important;
  font-size: 20 * $unit !important;
}
.ui.popup {
  padding: 0 !important;
}

.Desktop_menu_notifications_popup {
  width: 350 * $unit !important;
}

.Desktop_menu_notifications_popup_header {
  font-family: $font !important;
  font-size: 20 * $unit;
  height: 30 * $unit;
  margin-left: 27 * $unit;
  margin-top: 12 * $unit;
  // color: $primary;
}

.Desktop_menu_notifications_popup_footer {
  font-family: $font !important;
  font-size: 12 * $unit;
  height: 40 * $unit;
  color: $primary;
  position: relative;
}

.Desktop_menu_notifications_popup_footer_nav {
  position: absolute !important;
  bottom: 7 * $unit !important;
  left: 125 * $unit;
}

.Desktop_menu_notifications_popup_content {
  width: 350 * $unit;
  height: 100 * $unit;
  border: solid 1 * $unit rgba(219,215,215,0.5);
  // background-color: #edf2fa;
  padding-left: 27 * $unit;
  padding-top: 30 * $unit;
}
.Desktop_menu_notifications_popup_content_message {
  font-family: Avenir;
  font-size: 20 * $unit;
  font-weight: 500;
}
.Desktop_menu_notifications_popup_content_time {
  font-family: $font;
  font-size: 12 * $unit;
  margin-top: 5 * $unit;
  font-weight: 900;
}

.Desktop_container_footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: black;
  height: 150 * $unit;
}

.Footer_header_instance {
  margin-top:10*$unit !important;
}