html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  border: 0px;
  background-color: #f5f8fd;
  /* Represents 10px, please use rems (rem is based on root fontsize) */
  font-size: 62.5%;
  font-family: $font !important;
}

.Toastify__toast-body {
  font-size: 25 * $unit;
  line-height:25*$unit;
  font: $font;
}

.document_modal {
  text-align:center !important;
  font-size:20*$unit !important;
}
.document_modal_button {
  background-color: $secondary !important;
  color:white !important;
  padding-bottom: 17*$unit !important;
}
.ui.button {
  padding-top:15*$unit !important;
}