.Detail_container {
  margin: 0 !important;
}
.Detail_nav {
  background-color: white !important;
  display: flex;
}
.Detail_nav_left {
  display: inline-block;
}
.Detail_nav_left_location {
  margin-left: 30 * $unit !important;
  margin-top: 38 * $unit !important;
  margin-bottom: 38 * $unit !important;
  font-size: 30 * $unit !important;
  font-weight: 900 !important;
  width: 415 * $unit !important;
}
.Detail_nav_left_link_text {
  color: black !important;
}
.Detail_nav_center {
  display: inline-block;
  padding: 0 !important;
  text-align: center !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.Detail_nav_center_buttons {
  margin-top: 23 * $unit !important;
  margin-bottom: 23 * $unit !important;
}
.ui.button.Detail_nav_center_button_left {
  padding-top:17*$unit !important;
}
.ui.button.Detail_nav_center_button_right {
  padding-top:17*$unit !important;
}
.Detail_nav_center_button_left {
  width: 200 * $unit !important;
  height: 60 * $unit !important;
  font-size: 24 * $unit !important;
  border-radius: 0 !important;
  border-left: solid 0.2 * $unit rgba(219,215,215,0.5) !important;
  border-right: solid 0.2 * $unit rgba(219,215,215,0.5) !important;
  border-top: solid 0.2 * $unit rgba(219,215,215,0.5) !important;
  border-bottom: solid 0.2 * $unit rgba(219,215,215,0.5) !important;
}

.Detail_nav_center_button_right {
  width: 200 * $unit !important;
  height: 60 * $unit !important;
  font-size: 24 * $unit !important;
  border-radius: 0 !important;
  border-left: solid 0.2 * $unit rgba(219,215,215,0.5) !important;
  border-right: solid 0.2 * $unit rgba(219,215,215,0.5) !important;
  border-top: solid 0.2 * $unit rgba(219,215,215,0.5) !important;
  border-bottom: solid 0.2 * $unit rgba(219,215,215,0.5) !important;
}
.Detail_nav_right {
  width: 415 * $unit;
}
.Detail_main {
  padding: 0 !important;
}
