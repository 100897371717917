.Settings_security_field_container {
  height: 380 * $unit;
  padding-top: 30 * $unit;
  padding-left: 30 * $unit;
}
.Settings_security_field_item_container {
  margin-bottom: 30 * $unit;
}
.Settings_security_field_item_title {
  color: #707070;
  font-family: Avenir;
  font-size: 20 * $unit;
  margin-bottom: 15 * $unit;
}
.Settings_security_field_item_input {
  width: 550 * $unit;
  height: 45 * $unit;
  border-radius: 10 * $unit;
  border: solid 1 * $unit rgba(112,112,112,0.2);
  background-color: #f4f7f9;
  text-indent: 20 * $unit;
  font-size: 20 * $unit;
  font-family: $font;
}
.Settings_security_field_item_input::placeholder {
  color: #dbd7d7;
}

.Settings_security_save_button {
  background-color: $secondary;
  color: white;
  width: 200 * $unit;
  height: 40 * $unit;
  border-radius: 10 * $unit;
  font-size: 19 * $unit;
  font-family: $font;
  cursor: pointer;
}
.Settings_security_save_container {
  padding-top: 30 * $unit;
  padding-left: 30 * $unit;
  border-top: solid 1 * $unit rgba(219,215,215,0.5);
  height: 102 * $unit;
}
