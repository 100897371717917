.Active_menu_container {
  position: relative;
  bottom: 658 * $unit;
  left: 326 * $unit;
  width: 36 * $unit;
  height: 68 * $unit;
  border: solid 1 * $unit #dbd7d7;
  background-color: #ffffff;
}
.Active_menu_edit {
  height: 34 * $unit;
  font-size: 20 * $unit;
  border-bottom: solid 1 * $unit #dbd7d7;
  color: black !important;
}
.Active_menu_edit_icon {
  position: relative;
  left: 6 * $unit;
  top: 4 * $unit;
  width: 25 * $unit;
}
.Active_menu_more {
  height: 34 * $unit;
  font-size: 18 * $unit;
  border-bottom: solid 1 * $unit #dbd7d7;
  cursor: pointer;
}
.Active_menu_more_icon {
  position: relative;
  left: 6 * $unit;
  top: 11 * $unit;
}
#Active_menu_more_dropdown {
  position: absolute !important;
  margin: 0 !important;
  left: -7 * $unit;
  top: 22 * $unit;
  font-size: 15 * $unit !important;
  width: 170 * $unit !important;
  color: black !important;
}
.Active_menu_more_dropdown_delete {
  color: red !important;
}
.Active_menu_more_dropdown {
  color: black !important;
}
.Active_menu_delete_ribbon {
  position: relative;
  top: -70 * $unit !important;
  left: -342 * $unit !important;
  z-index: 500 !important;
  width: 341 * $unit !important;
}
