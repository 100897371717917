.List_nav {
  background-color: white !important;
  margin: 0 !important;
}
.List_nav_left {
  padding: 0 !important;
}
.List_nav_left_location {
  margin-left: 30 * $unit !important;
  margin-top: 38 * $unit !important;
  margin-bottom: 38 * $unit !important;
  font-size: 30 * $unit !important;
  font-weight: 900 !important;
}
.List_nav_center {
  padding: 0 !important;
  text-align: center !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.List_nav_center_buttons {
  margin-top: 23 * $unit !important;
  margin-bottom: 23 * $unit !important;
}
.List_nav_center_button_left {
  width: 200 * $unit !important;
  height: 60 * $unit !important;
  font-size: 24 * $unit !important;
  border-radius: 0 !important;
  background-color: white !important;
  color: black !important;
  border-left: solid 1 * $unit rgba(219,215,215,0.5) !important;
  border-top: solid 1 * $unit rgba(219,215,215,0.5) !important;
  border-bottom: solid 1 * $unit rgba(219,215,215,0.5) !important;
}
.List_nav_center_button_left.active {
  width: 200 * $unit !important;
  height: 60 * $unit !important;
  font-size: 24 * $unit !important;
  border-radius: 0 !important;
  background-color: #55bbec !important;
  color: white !important;
  border-left: solid 1 * $unit rgba(219,215,215,0.5) !important;
  border-top: solid 1 * $unit rgba(219,215,215,0.5) !important;
  border-bottom: solid 1 * $unit rgba(219,215,215,0.5) !important;
}
.List_nav_center_button_right {
  width: 200 * $unit !important;
  height: 60 * $unit !important;
  font-size: 24 * $unit !important;
  border-radius: 0 !important;
  background-color: white !important;
  color: black !important;
  border-right: solid 1 * $unit rgba(219,215,215,0.5) !important;
  border-top: solid 1 * $unit rgba(219,215,215,0.5) !important;
  border-bottom: solid 1 * $unit rgba(219,215,215,0.5) !important;
}
.List_nav_center_button_right.active {
  width: 200 * $unit !important;
  height: 60 * $unit !important;
  font-size: 24 * $unit !important;
  border-radius: 0 !important;
  background-color: #55bbec !important;
  color: white !important;
  border-right: solid 1 * $unit rgba(219,215,215,0.5) !important;
  border-top: solid 1 * $unit rgba(219,215,215,0.5) !important;
  border-bottom: solid 1 * $unit rgba(219,215,215,0.5) !important;
}

.List_nav_right {
  padding: 0 !important;
}
.List_nav_right_button {
  width: 277 * $unit !important;
  height: 60 * $unit !important;
  font-size: 24 * $unit !important;
  border-radius: 10 * $unit !important;
  margin-top: 23 * $unit !important;
  margin-bottom: 23 * $unit !important;
  margin-right: 30 * $unit !important;
  color: white !important;
  background-color: $primary !important;
}
.List_main {
  margin-top: 70 * $unit !important;
  margin-bottom: 70 * $unit !important;
  height: 100%;
}
.List_empty_filler {
  text-align: center;
  margin-top: 110 * $unit;
}
.List_empty_filler_image {
  width: 175 * $unit;
}
.List_empty_filler_top {
  font: $font;
  font-size: 25 * $unit;
  font-weight: 900;
  color: #969696;
  margin: 0;
  margin-top: 15 * $unit;
}
.List_empty_filler_bottom {
  font: $font;
  font-size: 25 * $unit;
  font-weight: 900;
  color: #969696;
}
.List_empty_filler_create {
  width: 219 * $unit;
  height: 51 * $unit;
  border-radius: 7 * $unit;
  background-color: $primary;
  color: white;
  font-size: 21 * $unit;
  border: none;
}
.TeamList_sub_grid_padding {
  padding-left:108*$unit !important;
}