.Card_label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
  font-size: 24 * $unit;
}

.Card_button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40 * $unit;
  line-height: 40 * $unit;
  padding: 0 22 * $unit;
  box-shadow: 0 4 * $unit 6 * $unit rgba(50, 50, 93, 0.11),
    0 1 * $unit 3 * $unit rgba(0, 0, 0, 0.08);
  color: #fff;
  border-radius: 4 * $unit;
  font-size: 15 * $unit;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background-color: #6772e5;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 25 * $unit;
}

.Card_form {
  margin-bottom: 60 * $unit;
  padding-bottom: 60 * $unit;
  width: 500 * $unit;
}

.Card_button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #7795f8;
  transform: translateY(-1 * $unit);
  box-shadow: 0 7 * $unit 14 * $unit rgba(50, 50, 93, 0.1),
    0 3 * $unit 6 * $unit rgba(0, 0, 0, 0.08);
}

.Card_seperator {
  margin-bottom: 20 * $unit;
}
