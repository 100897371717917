.Auth_background {
  background-color: #f5f8fd;
}
.Auth_form {
  min-height: 500 * $unit;
  min-width: 400 * $unit;
  border: 5 * $unit;
}
.Auth_rep {
  background-color: rgba(222, 222, 222, 1);
  min-width: 1000 * $unit;
  display: flex;
  align-items: flex-end;
}
.Auth_rep_image {
  min-width: 1000 * $unit;
}

.Auth_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  margin-top: 70 * $unit;
  margin-left: 250 * $unit;
  margin-right: 250 * $unit;
  background-color: white;
}
.Auth_form_logo_image {
  width: 300 * $unit;
  margin-top: 60 * $unit;
  margin-left: 80 * $unit;
  cursor: pointer;
}
.Auth_form_content {
  padding-top: 20 * $unit;
  padding-left: 20 * $unit;
  padding-bottom: 50 * $unit;
}

@media only screen and (max-width: 1600px) {
  .Auth_rep {
    min-width: 900 * $unit;
    width: 900 * $unit;
  }
  .Auth_rep_image {
    min-width: 900 * $unit;
    width: 900 * $unit;
  }
}
@media only screen and (max-width: 1455px) {
  .Auth_rep {
    min-width: 750 * $unit;
    width: 750 * $unit;
  }
  .Auth_rep_image {
    min-width: 750 * $unit;
    width: 750 * $unit;
  }
}
@media only screen and (max-width: 1355px) {
  .Auth_rep {
    display: none;
  }
  .Auth_form_container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
  .Auth_form_logo_image {
    width: 450 * $unit;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
  }
  .Auth_form_content {
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0;
  }
  .Auth_container {
    margin-top: 30 * $unit;
    margin-left: 50 * $unit;
    margin-right: 50 * $unit;
    background-color: white;
  }
  .Auth_form {
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (max-width: 992px) {
  .Auth_background {
    background-color: white;
  }
  html,
  body {
    background-color: white !important;
  }
}
