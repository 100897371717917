.Settings_integrations_account_container {
  height: 250 * $unit;
  padding-top: 50 * $unit;
  padding-left: 30 * $unit;
}
.Settings_integrations_account_create {
  height: 60 * $unit;
  width: 385 * $unit;
  font-size: 20 * $unit;
  font-family: Avenir;
  padding-top: 18 * $unit;
  padding-left: 20 * $unit;
  color: $primary;
  cursor: pointer;
}
.Settings_integrations_account_item_logo {
  height: 75 * $unit;
  width: 75 * $unit;
}
.Settings_integrations_account_item_container {
  box-shadow: 0 3 * $unit 6 * $unit 0 rgba(0, 0, 0, 0.16);
  width: 275 * $unit;
  height: 90 * $unit;
}

.Settings_integrations_account_item_logo {
  position: relative;
  left: 20 * $unit;
  top: 10 * $unit;
}
.Settings_integrations_account_item_name {
  position: relative;
  left: 111 * $unit;
  top: -55 * $unit;
  font-size: 20 * $unit;
}
.Settings_integrations_account_item_details {
  position: relative;
  left: 103 * $unit;
  top: -15 * $unit;
  font-family: Avenir;
  font-size: 18 * $unit;
  margin-top: 10 * $unit;
  margin-bottom: 10*$unit;
  color:$primary;
}
.Settings_integrations_account_item_details.connected {
  color: $secondary;
}

.Settings_Integrations_apps_instance_container {
  height: 135 * $unit;
  width: 285 * $unit;
  box-shadow: 0 3 * $unit 6 * $unit 0 rgba(0, 0, 0, 0.16);
  border-radius: 3 * $unit;
}
.Settings_Integrations_apps_instance_banner {
  height: 85 * $unit;
  width: 285 * $unit;
  background-color: $secondary;
}
.Settings_Integrations_apps_instance_banner_logo {
  height: 50 * $unit;
  width: 50 * $unit;
  background-color: white;
  border-radius: 5 * $unit;
  position: relative;
  top: 20 * $unit;
  left: 25 * $unit;
  padding: 2 * $unit;
}

.Settings_Integrations_apps_instance_banner_name {
  position: relative;
  top: -30 * $unit;
  left: 85 * $unit;
  color: white;
  font-size: 18 * $unit;
  font-family: $font;
}

.Settings_Integrations_apps_instance_banner_description {
  position: relative;
  top: -25 * $unit;
  left: 87 * $unit;
  font-size: 9 * $unit;
  font-family: Avenir;
  color: white;
}

.Settings_Integrations_apps_instance_options {
  height: 51 * $unit;
  padding-left: 80 * $unit;
  padding-top: 18 * $unit;
}
.Settings_Integrations_apps_instance_options_settings {
  font-size: 14 * $unit;
  font-family: Avenir;
  font-weight: 500;
  color: $primary;
  border: none;
}

.Settings_Integrations_apps_instance_options_connect {
  font-size: 14 * $unit;
  font-family: Avenir;
  color: $primary;
  border: none;
  margin-left: 90 * $unit;
}

.Settings_Integrations_apps_instance_options_connected {
  font-size: 14 * $unit;
  font-family: Avenir;
  color: #00FF00;
  border: none;
  margin-left: 90 * $unit;
  text-align:center
}
