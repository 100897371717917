.SignUp_container {
  margin-top: 65 * $unit;
}
.SignUp_header {
  font-size: 35 * $unit;
  font: $font;
  color: white;
  text-align: center;
  padding-bottom: 47 * $unit;
  margin-bottom: 0;
}
.SignUp_form_input_container {
  width: 500 * $unit;
}
.SignUp_form_input_companyName_container {
}
.SignUp_form_input_companyName_icon {
  position: absolute;
  left: 142 * $unit;
  top: 178 * $unit;
  z-index: 2;
}
.SignUp_form_input_companyName_icon::before {
  font-size: 30 * $unit;
  z-index: 2;
}
.SignUp_form_input_companyName_divider {
  position: absolute;
  left: 185 * $unit;
  top: 169 * $unit;
  height: 35 * $unit;
  border: solid 1 * $unit #707070;
  z-index: 2;
}
.SignUp_form_input_companyName {
  position: relative;
  left: 125 * $unit;
  height: 60 * $unit;
  width: 500 * $unit;
  -webkit-appearance: 0;
  margin-bottom: 40 * $unit;
  border-radius: 14 * $unit;
  border: 0px solid #58b9fa;
  text-indent: 80 * $unit;
  font-size: 25 * $unit;
  font: Avenir;
}
.SignUp_form_input_phone_container {
}
.SignUp_form_input_phone_icon {
  position: absolute;
  left: 140 * $unit;
  top: 278 * $unit;
  z-index: 2;
}
.SignUp_form_input_phone_icon::before {
  font-size: 35 * $unit;
  z-index: 2;
}
.SignUp_form_input_phone_divider {
  position: absolute;
  left: 185 * $unit;
  top: 269 * $unit;
  height: 35 * $unit;
  border: solid 1 * $unit #707070;
  z-index: 2;
}
.SignUp_form_input_phone {
  position: relative;
  left: 125 * $unit;
  height: 60 * $unit;
  width: 500 * $unit;
  -webkit-appearance: 0;
  margin-bottom: 40 * $unit;
  border-radius: 14 * $unit;
  border: 0px solid #58b9fa;
  text-indent: 80 * $unit;
  font-size: 25 * $unit;
  font: Avenir;
}

.SignUp_form_input_email_container {
}
.SignUp_form_input_email_icon {
  position: absolute;
  left: 141 * $unit;
  top: 277 * $unit;
  z-index: 2;
}
.SignUp_form_input_email_icon::before {
  font-size: 30 * $unit;
  z-index: 2;
}
.SignUp_form_input_email_divider {
  position: absolute;
  left: 185 * $unit;
  top: 267 * $unit;
  height: 37 * $unit;
  border: solid 1 * $unit #707070;
  z-index: 2;
}
.SignUp_form_input_email {
  position: relative;
  left: 125 * $unit;
  height: 60 * $unit;
  width: 500 * $unit;
  -webkit-appearance: 0;
  margin-bottom: 40 * $unit;
  border-radius: 14 * $unit;
  border: 0px solid #58b9fa;
  text-indent: 80 * $unit;
  font-size: 25 * $unit;
  font: Avenir;
}
.SignUp_form_input_password_container {
  border-radius: 14 * $unit;
}
.SignUp_form_input_password_icon {
  position: absolute;
  left: 142 * $unit;
  top: 376 * $unit;
  z-index: 2;
}
.SignUp_form_input_password_icon::before {
  font-size: 30 * $unit;
  z-index: 2;
}
.SignUp_form_input_password_divider {
  position: absolute;
  left: 185 * $unit;
  top: 367 * $unit;
  height: 35 * $unit;
  border: solid 1 * $unit #707070;
  z-index: 2;
}
.SignUp_form_input_password {
  position: relative;
  left: 125 * $unit;
  height: 60 * $unit;
  width: 500 * $unit;
  -webkit-appearance: 0;
  border-radius: 14 * $unit;
  border: 0px solid #58b9fa;
  text-indent: 80 * $unit;
  font-size: 25 * $unit;
  font: Avenir;
}
.SignUp_form_option_container {
  margin-top: 15 * $unit;
  margin-bottom:15*$unit;
}

.SignUp_form_option_remember {
  position: absolute;
  left: 145 * $unit;
  color: white;
  width: 450*$unit;
  overflow-wrap: break-word;
  letter-spacing: 2*$unit;
  line-height: 24*$unit;
}
.SignUp_form_option_remember label {
  margin-left: 5 * $unit;
  font-family: Avenir;
  font-size: 22 * $unit;
  line-height: 1.35;
  text-align: left;
  color: #ffffff;
  cursor: pointer;
}
.SignUp_form_option_remember input {
  cursor: pointer;
  position: absolute;
  left: -15 * $unit;
  top: 4 * $unit;
}
.SignUp_form_option_forgot {
  position: absolute;
  left: 410 * $unit;
  color: white !important;
}
.SignUp_form_option_forgot a {
  font-family: Avenir;
  font-size: 20 * $unit;
  line-height: 1.35;
  text-align: left;
  color: #ffffff;
}
.SignUp_form_option_forgot_icon {
  font-size: 18 * $unit !important;
}
.SignUp_form_input_logo_container {
  color: white;
  font-size: 20 * $unit;
  margin-top: 20 * $unit;
  height: 50 * $unit;
}
.SignUp_form_input_logo_form {
  position: absolute;
  left: 130 * $unit;
  top: 450 * $unit;
}

.SignUp_form_input_logo_form input {
  position: absolute;
  left: 240 * $unit;
}
.SignUp_form_input_logo_form span {
  position: relative;
  top: 6 * $unit;
}

.SignUp_form_submit_container {
  margin-top: 80 * $unit;
  text-align: center;
}
.SignUp_form_submit_container button {
  width: 301 * $unit;
  height: 70 * $unit;
  border-radius: 14 * $unit;
  background-color: $secondary;
  color: white;
  font-family: $font;
  font-size: 25 * $unit;
  border: 0;
  cursor: pointer;
}
.SignUp_helper_container {
  margin-top: 40 * $unit;
  font-family: $font;
  font-size: 20 * $unit;
  line-height: 1.4;
  text-align: left;
  color: #ffffff;
  text-align: center;
}

.SignUp_helper_container a {
  color: $secondary;
}
.SignUp_form_document {
  cursor: pointer;
  color:$secondary;
}
.SignUp_form_document:hover {
  color:white;
}