.Connected_Placeholder_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top:220*$unit;
}
.Connected_Placeholder_image_container {
  width:150*$unit;
  margin-top:18*$unit;
}
.Connected_Placeholder_text_container {
  margin-top:45*$unit;
  font-size:26*$unit;
  font-weight:900;
  color:#707070;
}