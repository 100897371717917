.Saved_menu_container {
  position: relative;
  bottom: 658 * $unit;
  left: 326 * $unit;
  width: 36 * $unit;
  height: 68 * $unit;
  border: solid 1 * $unit #dbd7d7;
  background-color: #ffffff;
}
.Saved_menu_edit {
  height: 34 * $unit;
  border-bottom: solid 1 * $unit #dbd7d7;
  color: black !important;
}
.Saved_menu_edit_icon {
  position: relative;
  left: 6 * $unit;
  top: 4 * $unit;
  width: 25 * $unit;
}
.Saved_menu_delete {
  height: 34 * $unit;
  font-size: 25 * $unit;
  border-bottom: solid 1 * $unit #dbd7d7;
  color: black !important;
  cursor: pointer;
}
.Saved_menu_delete_icon {
  position: relative;
  left: 8 * $unit;
  top: 11 * $unit;
}
