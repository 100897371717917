.Team_banner {
  position: relative;
  border: solid 1 * $unit rgba(219,215,215,0.5);
  width: 326 * $unit;
  height: 658 * $unit;
  background-color: white;
  cursor: pointer;
}
.Team_banner_top_background {
  position: absolute;
  top: 10 * $unit;
  left: 14 * $unit;
  background-color: $secondary;
  width: 298 * $unit;
  height: 152 * $unit;
}

.Team_banner_top_name {
  position: absolute;
  top: 40 * $unit;
  width: 300 * $unit;
  font-size: 30 * $unit;
  text-align: center;
  color: white;
}

.Team_banner_center_header {
  position: absolute;
  top: 100 * $unit;
  left: 33 * $unit;
  background-color: white;
  width: 259 * $unit;
  height: 193 * $unit;
  border-radius: 24 * $unit;
}

.Team_banner_center_header_container {
  height: 75 * $unit;
  padding: 15 * $unit;
  text-align:center;
}

.Team_banner_center_header_logo {
  width: 220*$unit;
  text-align:center;
}

.Team_banner_center_header_price {
  font-size: 37 * $unit;
  text-align: center;
  margin-top: 10 * $unit;
}
.Team_banner_center_header_text {
  font-size: 22 * $unit;
  text-align: center;
  margin-top: 12 * $unit;
}
.Team_banner_center_header_rating_container {
  position: relative;
  top:2*$unit;
  margin-top: 12.6 * $unit;
  text-align: center;
  border-radius: 34 * $unit;
  border: solid 1 * $unit rgba(219, 215, 215, 0.5);
  width: 230 * $unit;
  height: 34 * $unit;
}
.Team_banner_center_header_rating_rating {
  position: absolute;
  left: 22 * $unit;
  top: 8 * $unit;
}

.Team_banner_center_header_rating_reviews {
  position: absolute;
  left: 145 * $unit;
  top: 8 * $unit;
  font-size: 16 * $unit;
  text-decoration: underline;
  cursor:pointer;
}
.Team_banner_description_container {
  background-color: #faf9fa;
  height: 238 * $unit;
  width: 298 * $unit;
  position: absolute;
  top: 310 * $unit;
  left: 14 * $unit;
  padding: 18 * $unit 18 * $unit 18 * $unit 18 * $unit;
  overflow-wrap: break-word;
  overflow: hidden;
  overflow-y:hidden;
}
.Team_banner_description_text {
  font-size: 17 * $unit;
  height: 258 * $unit;
  color: black;
  line-height: 1.43;
  overflow-wrap: break-word;
  overflow: hidden;
  overflow-y:hidden;
}
.Team_banner_footer_container {
  position: absolute;
  top: 570 * $unit;
  left: 14 * $unit;
  width: 298 * $unit;
}
.Team_banner_footer_title {
  text-align: center;
  font-size: 15 * $unit;
  color: black;
  margin-top: 10 * $unit;
}
.Team_banner_footer_stats_container {
  height: 36 * $unit;
}
.Team_banner_footer_stats_one {
  display: inline-block;
  width: 73 * $unit;
  height: 36 * $unit;
  background-image: linear-gradient(to left, rgba(0, 92, 148, 0.87), rgba(0, 46, 74, 0.84));
  margin-bottom: 10 * $unit;
  color: white;
}

.Team_banner_footer_stats_one_title {
  font-size: 6px !important;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 0;
}

.Team_banner_footer_stats_one_stat {
  font-size: 16 * $unit;
  text-align: center;
}

.Team_banner_footer_stats_two {
  margin-left: 2 * $unit;
  display: inline-block;
  width: 73 * $unit;
  height: 36 * $unit;
  background-image: linear-gradient(to left, #297cce, #045fba);
  margin-bottom: 10 * $unit;
  color: white;
}

.Team_banner_footer_stats_two_title {
  font-size: 6px !important;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 0;
}

.Team_banner_footer_stats_two_stat {
  font-size: 16 * $unit;
  text-align: center;
}

.Team_banner_footer_stats_three {
  margin-left: 2 * $unit;
  display: inline-block;
  width: 73 * $unit;
  height: 36 * $unit;
  background-image: linear-gradient(to left, #359ff9, #1686e5);
  margin-bottom: 10 * $unit;
  color: white;
}

.Team_banner_footer_stats_three_title {
  font-size: 6px !important;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 0;
}

.Team_banner_footer_stats_three_stat {
  font-size: 16 * $unit;
  text-align: center;
}

.Team_banner_footer_stats_four {
  margin-left: 2 * $unit;
  display: inline-block;
  width: 73 * $unit;
  height: 36 * $unit;
  background-image: linear-gradient(to left, #12b0f2, #00a3e6);
  margin-bottom: 10 * $unit;
  color: white;
}

.Team_banner_footer_stats_four_title {
  font-size: 6px !important;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 0;
}

.Team_banner_footer_stats_four_stat {
  font-size: 16 * $unit;
  text-align: center;
}