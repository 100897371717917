.Compensation_Container_Body {
  background-color: white;
  display: flex;
  justify-content: space-between;
}

.Compensation_progress_bar {
  height:242*$unit;
  position: absolute;
  border-left: 2*$unit dotted rgba(219,215,215,0.5);
  left:50*$unit;
  top:222*$unit;
}
.Compensation_progress_bar.finished {
  border-left: 2*$unit dotted $primary;
}
.Compensation_progress_bubble_1 {
  -moz-border-radius: 50px/50px;
  -webkit-border-radius: 50px 50px;
  border-radius: 50px/50px;
  border: solid 1*$unit rgba(219,215,215,0.5);
  background-color:rgba(219,215,215,0.5);
  width: 10*$unit;
  height: 10*$unit;
  position:absolute;
  left:-6*$unit;
  top:-3*$unit;
}
.Compensation_progress_bubble_1.finished {
  border: solid 1*$unit $primary;
  background-color:$primary;
}
.Compensation_progress_bubble_2 {
  -moz-border-radius: 50px/50px;
  -webkit-border-radius: 50px 50px;
  border-radius: 50px/50px;
  border: solid 1*$unit rgba(219,215,215,0.5);
  background-color:rgba(219,215,215,0.5);
  width: 10*$unit;
  height: 10*$unit;
  position:absolute;
  left:-6*$unit;
  top:117*$unit;
}
.Compensation_progress_bubble_2.finished {
  border: solid 1*$unit $primary;
  background-color:$primary;
}
.Compensation_progress_bubble_3 {
  -moz-border-radius: 50px/50px;
  -webkit-border-radius: 50px 50px;
  border-radius: 50px/50px;
  border: solid 1*$unit rgba(219,215,215,0.5);
  background-color:rgba(219,215,215,0.5);
  width: 10*$unit;
  height: 10*$unit;
  position:absolute;
  left:-6*$unit;
  top:234*$unit;
}
.Compensation_progress_bubble_3.finished {
  border: solid 1*$unit $primary;
  background-color:$primary;
}

.Compensation_Main_Content {
  width: 100% !important;
  margin-top: 40 * $unit;
  min-height: 620*$unit;
}
.Compensation_Main_Content_questions_instance {
  height: 48*$unit;
  width:1000*$unit;
  margin-top:25*$unit;
  margin-bottom:70*$unit;
  text-align:left;
  margin-left:65*$unit;
  margin-right:125*$unit;
}
.ui.selection.dropdown.Compensation_Main_Content_questions_instance_input {
  border: solid 1*$unit rgba(219,215,215,0.5) !important;
  border-radius:9*$unit !important;
  height:48*$unit !important;
  font-size:20*$unit !important;
  float:right !important;
  padding-left: 56*$unit !important;
  padding:none !important;
}

.Compensation_Main_Content_questions_instance_input div {
  font-size: 20*$unit;
}

.Compensation_Main_Content_questions_instance_text {
  font-size:21*$unit;
  font-weight:900;
  float:left;
  margin-top:10*$unit;
}
.Compensation_Main_Content_questions_instance_input {
  border: solid 1*$unit rgba(219,215,215,0.5) !important;
  border-radius:9*$unit !important;
  height:48*$unit !important;
  font-size:20*$unit !important;
  float:right !important;
  padding-left: 53*$unit !important;
}

.Compensation_Main_Content_prices {
  width:900*$unit;
  height:200*$unit;
  padding-top:30*$unit;
  margin-left: auto;
  margin-right: auto;
}
.Compensation_Main_Content_prices_recommended {
  display:inline-block;
  width: 394*$unit;
  height: 140*$unit;
  border-radius: 12*$unit;
  box-shadow: 0 3*$unit 6*$unit 0 rgba(0, 0, 0, 0.16);
  cursor:pointer;
}
.Compensation_Main_Content_prices_recommended.selected {
  background-color:$primary;
  color:white;
}
.Compensation_Main_Content_prices_chosen {
  display:inline-block;
  width: 394*$unit;
  height: 140*$unit;
  border-radius: 12*$unit;
  box-shadow: 0 3*$unit 6*$unit 0 rgba(0, 0, 0, 0.16);
  margin-left:70*$unit;
}
.Compensation_Main_Content_prices_chosen.selected {
  background-color:$primary;
  color:white;
}
.Compensation_Main_Content_prices_recommended_title {
  font-size:20*$unit;
  text-align:center;
  margin-top:25*$unit;
}
.Compensation_Main_Content_prices_recommended_input {
  width:192*$unit;
  font-size:50*$unit;
  margin-top:15*$unit;
  width:100%;
  height:50*$unit;
  text-align:center;
  border:none;
  cursor:pointer !important;
}
.Compensation_Main_Content_prices_recommended_input .rc-input-number-input-wrap input {
  cursor:pointer !important;
}
.rc-input-number.Compensation_Main_Content_prices_recommended_input {
  cursor:pointer !important;
}
.Compensation_Main_Content_prices_recommended_input.selected .rc-input-number-input {
  background-color:$primary !important;
  color:white !important;
}

.rc-input-number-disabled .rc-input-number-input {
  background-color:white;
}
.rc-input-number-handler-wrap {
  display:none;
}
.rc-input-number-focused {
  box-shadow: none !important;
  text-decoration:none !important;
  outline:none !important;
  border:none;
}
*:focus {
  outline:0 !important;
}
.rc-input-number.Compensation_Main_Content_questions_instance_input.rc-input-number-focused {
  border: solid 1*$unit rgba(219,215,215,0.5) !important;
}
.Compensation_Main_Content_questions_instance_input input.rc-input-number-input {
  padding-left: 0 !important;
  text-align:left;
  border-bottom: solid 1*$unit rgba(219,215,215,0.5) !important;
  border-top: solid 1*$unit rgba(219,215,215,0.5) !important;
}
.Compensation_Main_Content_questions_instance_input input.rc-input-number-input::placeholder {
  color: rgba(191,191,191,.87) !important;
}
.ui.selection.dropdown.Compensation_Main_Content_questions_instance_input i {
  color:rgba(191,191,191,.87) !important;
}
.Compensation_Main_Content_ribbon {
  width: 100*$unit;
  height: 10*$unit;
  transform:rotate(-45deg);
  border-bottom: 18*$unit solid #6ec31e;
	border-left: 18*$unit solid transparent;
	border-right: 18*$unit solid transparent;
  position:absolute;
  top:609.35*$unit;
  left:163*$unit;
}
.Compensation_Main_Content_prices_recommended_profit {
  font-size:5*$unit;
  text-align:center;
  margin-top:10*$unit;
}
.Hidden_stuff {
  position:absolute;
  top:691*$unit;
  left:280*$unit;
}
.Hidden_stuff2 {
  position:absolute;
  top:691*$unit;
  left:745*$unit;
}

