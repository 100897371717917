.Payments_history_container {
  width: 1100 * $unit;
  background-color: white;
  padding-top: 30 * $unit;
  padding-bottom: 30 * $unit;
  border: solid 1 * $unit rgba(219,215,215,0.5)

}

.Payments_history_banner_container {
  position: relative;
  left: 200 * $unit;
  width: 700 * $unit;
  height: 155 * $unit;
}
.Payments_history_banner_balance {
  position: absolute;
  width: 350 * $unit;
  height: 155 * $unit;
  background-color: white;
  border-top-left-radius: 32 * $unit;
  border-bottom-left-radius: 32 * $unit;
  box-shadow:0 3*$unit 6*$unit 0 rgba(0, 0, 0, 0.16);
}
.Payments_history_banner_balance_title {
  position: relative;
  top: 30 * $unit;
  left: 72 * $unit;
  font-size: 25 * $unit;
  font-family: $font;
  color: black;
}
.Payments_history_banner_balance_amount {
  position: relative;
  top: 72 * $unit;
  left: 140 * $unit;
  font-size: 40 * $unit;
  font-family: Avenir;
  color: black;
}

.Payments_history_banner_last {
  position: absolute;
  left: 350 * $unit;
  width: 350 * $unit;
  height: 155 * $unit;
  background-color: $primary;
  border-top-right-radius: 32 * $unit;
  border-bottom-right-radius: 32 * $unit;
  box-shadow:0 3*$unit 6*$unit 0 rgba(0, 0, 0, 0.16);
}
.Payments_history_banner_last_title {
  position: relative;
  top: 30 * $unit;
  left: 110 * $unit;
  font-size: 25 * $unit;
  font-family: $font;
  color:white;
}
.Payments_history_banner_last_amount {
  position: relative;
  top: 72 * $unit;
  left: 125 * $unit;
  font-size: 40 * $unit;
  font-family: Avenir;
  color:white;

}

.Payments_history_banner_bank {
  position: absolute;
  top: 28 * $unit;
  left: 300 * $unit;
  width: 100 * $unit;
  height: 100 * $unit;
  border-radius: 50%;
  box-shadow: 2 * $unit 6 * $unit 10 * $unit 0 rgba(0, 0, 0, 0.16);
  background-color: white;
  z-index: 15;
}
.Payments_history_banner_bank_image {
  width: 90 * $unit;
  margin-left: 5 * $unit;
  margin-top: 28 * $unit;
  height: auto;
}
.Payments_history_list_container {
  width: 80%;
  margin: auto;
}
.Payments_history_list_title {
  margin-top: 30 * $unit;
  font-size: 25 * $unit;
  font-family: $font;
  color: $secondary;
}

.Payments_list_grid {
  margin-top: 38 * $unit;
  min-height: 500 * $unit;
}
.Payments_list_grid_item {
  margin-bottom: 54 * $unit;
  width: 821 * $unit;
  height: 146 * $unit;
  border-radius: 10 * $unit;
  box-shadow: -3 * $unit 5 * $unit 12 * $unit 0 rgba(0, 0, 0, 0.16);
}
.Payments_list_grid_item_top_container {
  display: flex;
  align-items: center;
  margin-bottom: 21 * $unit;
}
.Payments_list_grid_item_top_status {
  width: 121 * $unit;
  height: 40 * $unit;
  border-radius: 20 * $unit;
  margin-top: 20 * $unit;
  margin-left: 30 * $unit;
}
.Payments_list_grid_item_top_status.success {
  background-image: linear-gradient(70deg, #06efb0, #00d3a9);
}
.Payments_list_grid_item_top_status.pending {
  background-color:#ffc001;
}
.Payments_list_grid_item_top_status.failed {
  background-color:#f60020;
}
.Payments_list_grid_item_top_status_text {
  font-family: Avenir;
  font-size: 20 * $unit;
  color: white;
  margin-top: 12 * $unit;
  text-align: center;
}
.Payments_list_grid_item_top_amount {
  font-family: Avenir;
  font-size: 30 * $unit;
  margin-top: 20 * $unit;
  margin-left: 20 * $unit;
  font-weight: 500;
  text-align: center;
}
.Payments_list_grid_item_top_date {
  font-family: Avenir;
  font-size: 20 * $unit;
  margin-top: 20 * $unit;
  margin-left: 30 * $unit;
  font-weight: 500;
  text-align: center;
}
.Payments_list_grid_item_top_id {
  font-family: Avenir;
  font-size: 16 * $unit;
  margin-top: 18 * $unit;
  margin-left: 30 * $unit;
  font-weight: 500;
  text-align: center;
}
.Payments_list_grid_item_top_dispute {
  width: 120 * $unit;
  height: 40 * $unit;
  border-radius: 20 * $unit;
  background-color: red;
  margin-top: 21 * $unit;
  margin-left: auto;
  margin-right: 18 * $unit;
  order: 2;
  cursor: pointer;
}
.Payments_list_grid_item_top_dispute_text {
  font-family: Avenir;
  font-size: 15 * $unit;
  color: white;
  margin-top: 14 * $unit;
  text-align: center;
}

.Payments_list_grid_item_top_receipt {
  width: 120 * $unit;
  height: 40 * $unit;
  border-radius: 20 * $unit;
  background-color: $secondary;
  margin-top: 21 * $unit;
  margin-left: auto;
  margin-right: 18 * $unit;
  order: 2;
  cursor: pointer;
}
.Payments_list_grid_item_top_receipt_text {
  font-family: Avenir;
  font-size: 15 * $unit;
  color: white;
  margin-top: 14 * $unit;
  text-align: center;
}

.Payments_list_grid_item_divider {
  width: 821 * $unit;
  height: 0;
  border: solid 1 * $unit rgba(219,215,215,0.5);
}

.Payments_list_grid_item_description {
  font-family: Avenir;
  font-size: 15 * $unit;
  font-weight: 500;
  margin-top: 23 * $unit;
  margin-left: 38 * $unit;
}
