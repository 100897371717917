.RateRep_input {
  height: 40 * $unit;
  width: 500 * $unit;
  -webkit-appearance: 0;
  margin-bottom: 20 * $unit;
  margin-top: 20 * $unit;
  border-radius: 14 * $unit;
  border: 0px solid #58b9fa;
  text-indent: 10 * $unit;
  font-size: 20 * $unit;
  font: Avenir;
}
.RateRep_submit {
  width: 351 * $unit;
  height: 70 * $unit;
  border-radius: 14 * $unit;
  background-color: $secondary;
  color: white;
  font-family: $font;
  font-size: 25 * $unit;
  border: 0;
  cursor: pointer;
}