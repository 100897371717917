.Settings_company_logo_button_upload {
  display: block;
  background-color: $secondary !important;
  color: white !important;
  font-family: $font;
  font-size: 18 * $unit;
  color: #707070;
  cursor: pointer;
  padding: 5 * $unit;
  text-align: center;
  margin-top: 20 * $unit !important;
  margin-left: 160 * $unit !important;
  margin-bottom: 5*$unit !important;
}
.Image_Crop {
  margin-bottom: 25*$unit;
}

.ui.icon.left.labeled.button.Settings_company_logo_button_upload {
  padding-bottom:16*$unit!important;
}