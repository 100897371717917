.Settings_comms_header {
  height: 85 * $unit;
  border-bottom: solid 1 * $unit rgba(219,215,215,0.5);
  font-family: $font;
  font-size: 25 * $unit;
  font-weight: 900;
  padding-top: 30 * $unit;
  padding-left: 30 * $unit;
}
.Settings_comms_fields_container {
  height: 150 * $unit;
}

.Settings_comms_field_email_container {
  height: 85 * $unit;
}
.Settings_comms_field_email_title {
  margin-top: 30 * $unit;
  margin-bottom: 20 * $unit;
  margin-left: 30 * $unit;
  font-family: Avenir;
  font-size: 25 * $unit;
  font-weight: 500;
  color: #707070;
}
.Settings_comms_field_email_input {
  display: inline;
  margin-left: 30 * $unit;
  font-size: 22 * $unit;
}
.Settings_comms_field_email_edit {
  display: inline;
  margin-left: 15 * $unit;
  font-size: 15 * $unit;
  color: $secondary;
  cursor: pointer;
}

.Settings_comms_field_phone_container {
  height: 95 * $unit;
}
.Settings_comms_field_phone_title {
  margin-top: 30 * $unit;
  margin-bottom: 20 * $unit;
  margin-left: 30 * $unit;
  font-family: Avenir;
  font-size: 25 * $unit;
  font-weight: 500;
  color: #707070;
}
.Settings_comms_field_phone_input {
  display: inline;
  margin-left: 30 * $unit;
  font-size: 22 * $unit;
}
.Settings_comms_field_phone_edit {
  display: inline;
  margin-left: 15 * $unit;
  font-size: 15 * $unit;
  color: $secondary;
  cursor: pointer;
}
