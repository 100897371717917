.Detail_manage_rep_header {
  height: 85 * $unit;
}
.Detail_manage_rep_header_main {
  font-size: 20 * $unit;
}
.Detail_manage_rep_header_stat {
  font-size: 25 * $unit !important;
  color: $secondary;
}
.Detail_manage_rep_header_sub {
  font-size: 15 * $unit;
  font-weight: 900;
  color: #7b7b7b;
  margin-top: 5 * $unit;
}
.Detail_manage_rep_approve_temps {
  position:absolute;
  right:55*$unit;
  top: 280*$unit;
  width: 200 * $unit !important;
  height: 50 * $unit !important;
  font-size: 20 * $unit !important;
  border-radius: 10 * $unit !important;
  color: white !important;
  background-color: $primary !important;
  float:right;
  border:none !important;
}
.Detail_manage_rep_chosen_container {
  background-color: white;
  height: 140 * $unit;
  text-align: center;
}

.Detail_manage_rep_chosen_container {
  display: flex;
  justify-content: center;
  border: solid 1 * $unit rgba(219,215,215,0.5)
}
.Detail_manage_rep_chosen_box {
  width: 100 * $unit;
  margin-left: 20 * $unit;
  margin-right: 20 * $unit;
}
.Detail_manage_rep_chosen_box:first-child {
  margin-left: 0 !important;
}
.Detail_manage_rep_chosen_box:last-child {
  margin-right: 0 !important;
}
.Detail_manage_rep_chosen_image {
  margin-top: 12 * $unit;
  width: 85 * $unit;
  height: 85 * $unit;
  border-radius: 50%;
}
.Detail_manage_rep_chosen_remove {
  width: 81 * $unit;
  height: 22 * $unit;
  border-radius: 5 * $unit;
  border: solid 1 * $unit #f60020;
  color: #f60020;
  background-color: #ffffff;
  font-family: $font;
  font-size: 15 * $unit;
  font-weight: 900;
  padding-top: 3 * $unit;
  padding-bottom: 20*$unit !important;
  position: relative;
  top: -18 * $unit;
}
.Detail_manage_rep_chosen_remove:hover {
  background-color: red;
  color: white;
  cursor: pointer;
}
.Detail_manage_rep_chosen_name {
  font-family: $font;
  font-size: 15 * $unit;
  font-weight:900;
  position: relative;
  letter-spacing: 1*$unit;
  top: -15 * $unit;
}

.Detail_manage_rep_carousel_container {
  background-color: #ffffff;
  margin-top: 40 * $unit;
  padding-top: 40 * $unit;
  height: 590 * $unit;
  display: flex;
  justify-content: center;
  border: solid 1 * $unit rgba(219,215,215,0.5)
}
.Detail_mange_rep_carousel_slider {
  width: 1350 * $unit;
  height: 590 * $unit;
}

.Detail_manage_rep_carousel_item {
  padding-left: 40 * $unit;
  padding-top: 3 * $unit;
  padding-bottom: 15 * $unit;
}

.slick-prev {
  color: $secondary;
  top: 250 * $unit;
}
.slick-prev::before {
  color: $secondary !important;
}

.slick-next {
  color: $secondary;
  top: 250 * $unit;
}
.slick-next::before {
  color: $secondary !important;
}
.Detail_manage_rep_chosen_fire_button {
  color:white;
  background-color:red;
  width: 170*$unit;
  height: 40*$unit;
  border-radius: 10*$unit;
  font:$font;
  font-size:17*$unit !important;
}
.Modal_fire_rep {
  text-align:center !important;
}

.Detail_manage_rep_approve_temps_confirm {
  width: 200 * $unit !important;
  height: 50 * $unit !important;
  font-size: 20 * $unit !important;
  border-radius: 10 * $unit !important;
  color: white !important;
  background-color: $primary !important;
}
.Detail_manage_rep_header_confirm_header {
  font-size:25*$unit;
  line-height:29*$unit;
}


.Choose_Rep_Placeholder_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom:110*$unit;
}
.Choose_Rep_Placeholder_image_container {
  width:140*$unit;
}
.Choose_Rep_Placeholder_text_container {
  margin-top:45*$unit;
  font-size:26*$unit;
  font-weight:900;
  color:#707070;
}
.Choose_Rep_Icon_Placeholder_image_container {
  width:100*$unit;
  margin-top:18*$unit;
}