.Integrations_apps_container {
  width: 1208 * $unit;
  height: 830 * $unit;
  background-color: white;
  padding-left: 141 * $unit;
  padding-right: 141 * $unit;
  padding-top: 40 * $unit;
}
.Integrations_apps_instance_container {
  height: 135 * $unit;
  width: 285 * $unit;
  box-shadow: 0 3 * $unit 6 * $unit 0 rgba(0, 0, 0, 0.16);
  border-radius: 3 * $unit;
}
.Integrations_apps_instance_banner {
  height: 85 * $unit;
  width: 285 * $unit;
  background-color: $secondary;
}
.Integrations_apps_instance_banner_logo {
  height: 50 * $unit;
  width: 50 * $unit;
  background-color: white;
  border-radius: 5 * $unit;
  position: absolute;
  top: 30 * $unit;
  left: 35 * $unit;
  padding: 2 * $unit;
}

.Integrations_apps_instance_banner_name {
  position: absolute;
  top: 40 * $unit;
  left: 100 * $unit;
  color: white;
  font-size: 18 * $unit;
  font-family: $font;
}

.Integrations_apps_instance_banner_description {
  position: absolute;
  top: 60 * $unit;
  left: 100 * $unit;
  font-size: 9 * $unit;
  font-family: Avenir;
  color: white;
}

.Integrations_apps_instance_options {
  height: 51 * $unit;
  padding-left: 80 * $unit;
  padding-top: 18 * $unit;
}
.Integrations_apps_instance_options_settings {
  font-size: 14 * $unit;
  font-family: Avenir;
  font-weight: 500;
  color: $primary;
  border: none;
}

.Integrations_apps_instance_options_connect {
  font-size: 14 * $unit;
  font-family: Avenir;
  color: $primary;
  border: none;
  margin-left: 90 * $unit;
}

.Integrations_apps_instance_options_connected {
  font-size: 14 * $unit;
  font-family: Avenir;
  color: $secondary;
  border: none;
  margin-left: 90 * $unit;
  text-align:center;
  cursor:auto;
}
