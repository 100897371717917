.Settings_company_header {
  height: 85 * $unit;
  border-bottom: solid 1 * $unit rgba(219,215,215,0.5);
  font-family: $font;
  font-size: 25 * $unit;
  font-weight: 900;
  padding-top: 30 * $unit;
  padding-left: 30 * $unit;
}
.Settings_company_flex_container {
  display: flex;
}
.Settings_company_fields_container {
  flex: 50%;
}

.Settings_company_field_name_container {
  height: 85 * $unit;
}
.Settings_company_field_name_title {
  margin-top: 40 * $unit;
  margin-bottom: 20 * $unit;
  margin-left: 30 * $unit;
  font-family: Avenir;
  font-size: 25 * $unit;
  font-weight: 500;
  color: #707070;
}
.Settings_company_field_name_input {
  margin-left: 30 * $unit;
  font-size: 22 * $unit;
}
.Settings_company_field_name_edit {
  font-size: 15 * $unit;
  color: $secondary;
  cursor: pointer;
  margin-top:13*$unit;
  margin-left: 32 * $unit;
}

.Settings_company_field_description_container {
  height: 115 * $unit;
}
.Settings_company_field_description_title {
  margin-top: 30 * $unit;
  margin-bottom: 20 * $unit;
  margin-left: 30 * $unit;
  font-family: Avenir;
  font-size: 25 * $unit;
  font-weight: 500;
  color: #707070;
}
.Settings_company_field_description_input {
  margin-left: 30 * $unit;
  font-size: 22 * $unit;
  overflow-wrap:break-word;
  width:700*$unit;
  line-height: 25*$unit;
}
.Settings_company_field_description_edit {
  margin-left: 15 * $unit;
  font-size: 15 * $unit;
  color: $secondary;
  cursor: pointer;
  margin-top:8*$unit;
  margin-left:32*$unit;
}
.Settings_company_logo_container {
  flex: 50%;
}
.Settings_company_logo_image {
  margin-top: 50 * $unit;
  width: 400 * $unit;
  display: block;
}
.Settings_company_logo_button {
  display: block;
  background-color: $secondary !important;
  color: white !important;
  font-family: $font;
  font-size: 18 * $unit;
  color: #707070;
  cursor: pointer;
  padding-bottom:16*$unit !important;
  text-align: center;
  margin-top: 15 * $unit !important;
  margin-left: 105 * $unit !important;
}
.Settings_company_logo_button i{
  top: -2*$unit !important;
}
.Settings_company_logo_input {
  visibility:hidden;
}
.Settings_ImageCrop_info {
  position:relative;
  top:-40*$unit;
  left:320*$unit;
  font-size:18*$unit;
}
.Settings_company_logo_ImageCrop {
  position:absolute !important;
  left:20*$unit !important;
}